import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { collection } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { ColumnDef } from '@tanstack/react-table';
import {
  Button,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';

import { db } from 'lib/firebase';
import { editAtom, removeAtom } from '.';
import { Quiz, QuizConverter } from '../domain/Quiz';
import { GenericTable } from 'components/react-table/GenericTable';
import { SummaryStatusBadge } from 'components/StatusBadge';

export const QuizzesList = () => {
  const [, setEdit] = useAtom(editAtom);
  const [, setRemove] = useAtom(removeAtom);

  const [value, loading] = useCollectionData(
    collection(db, 'quizzes').withConverter(QuizConverter)
  );

  const data: Quiz[] = useMemo(() => {
    if (!value) return [];
    return value.map((item) => item);
  }, [value]);

  const columns: ColumnDef<Quiz>[] = useMemo(
    () => [
      {
        accessorKey: 'question',
        header: () => '질문',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'dueDate',
        header: () => '출제일',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'status',
        header: () => '상태',
        cell: (info) => (
          <SummaryStatusBadge state={info.getValue() as string} />
        ),
      },
      {
        accessorKey: 'createdAt',
        header: () => '등록일시',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'actions',
        header: () => '',
        cell: (info) => (
          <HStack spacing="1" justifyContent="flex-end">
            <IconButton
              icon={<FiEdit fontSize="1rem" />}
              variant="ghost"
              aria-label="Edit"
              onClick={() => setEdit(info.row.original!.id)}
            />
            <IconButton
              icon={<FiTrash2 fontSize="1.2rem" />}
              variant="ghost"
              color="red.300"
              aria-label="Delete"
              onClick={() => setRemove(info.row.original!)}
            />
          </HStack>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Stack spacing={{ base: '8', lg: '6' }}>
      <Stack
        spacing="4"
        direction={{ base: 'column', lg: 'row' }}
        justify="space-between"
        align={{ base: 'start', lg: 'center' }}
      >
        <Stack spacing="1">
          <Heading size={{ base: 'xs', lg: 'sm' }} fontWeight="medium">
            퀴즈
          </Heading>
        </Stack>
        <HStack spacing="3">
          <Button variant="primary" onClick={() => setEdit('new')}>
            퀴즈 작성
          </Button>
        </HStack>
      </Stack>

      {loading ? (
        <Spinner />
      ) : (
        <GenericTable<Quiz>
          name="퀴즈 목록"
          data={data}
          columns={columns}
          initialSorting={[{ id: 'createdAt', desc: true }]}
          unsearchable={true}
        />
      )}
    </Stack>
  );
};
