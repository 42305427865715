import { InputHTMLAttributes, useEffect, useState } from 'react';
import { InputGroup, InputLeftElement, Icon, Input } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';

export const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <InputGroup maxW="xs">
      <InputLeftElement pointerEvents="none">
        <Icon as={FiSearch} color="muted" boxSize="5" />
      </InputLeftElement>
      <Input
        type="search"
        value={value}
        placeholder="검색"
        fontSize="sm"
        onChange={(e) => setValue(e.target.value)}
      />
    </InputGroup>
  );
};
