import { useCallback, useMemo } from 'react';
import { useAtom } from 'jotai';
import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
} from '@chakra-ui/react';

import { showAtom } from '.';
import { db } from 'lib/firebase';
import { WordConverter } from '../domain/Word';
import { TodayWord } from './TodayWord';

export const WordShow = () => {
  const [show, setShow] = useAtom(showAtom);
  const isOpen = useMemo(() => !!show, [show]);
  const onClose = useCallback(() => setShow(null), [setShow]);

  const [word] = useDocumentData(
    show ? doc(db, 'words', show).withConverter(WordConverter) : null
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>한마디</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={5}>
            <TodayWord word={word} />
            {word && <Box fontSize="sm">{word.body}</Box>}
          </Stack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
