import { ReactNode } from 'react';
import { Box, Container, Flex, useBreakpointValue } from '@chakra-ui/react';

import { Sidebar } from './Sidebar';
import { Navbar } from './Navbar';

export const Layout = ({ children }: { children: ReactNode }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Flex
      as="section"
      direction={{ base: 'column', lg: 'row' }}
      height="100vh"
      bg="bg-canvas"
    >
      {isDesktop ? <Sidebar /> : <Navbar />}

      <Box flex="1" overflowY="auto">
        <Container py="8">{children}</Container>
      </Box>
    </Flex>
  );
};
