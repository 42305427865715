import { atom, Provider } from 'jotai';

import { Encashment } from '../domain/Encashment';
import { EncashmentsList } from './EncashmentsList';
import { EncashmentShow } from './EncashmentShow';
import { EncashmentDelete } from './EncashmentDelete';
import { ImageShow } from './ImageShow';

const encashmentScope = Symbol();
// export const editAtom = atom<string | null>(null);
export const showAtom = atom<Encashment | null>(null);
export const removeAtom = atom<Encashment | null>(null);
export const iShowAtom = atom<string | null>(null);

export const Encashments = () => {
  return (
    <Provider scope={encashmentScope}>
      <EncashmentsList />
      <EncashmentShow />
      <EncashmentDelete />
      <ImageShow />
    </Provider>
  );
};
