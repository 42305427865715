import { useMemo, useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Stack,
  useColorModeValue,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  Select,
  FormHelperText,
  Spinner,
} from '@chakra-ui/react';

import { db } from 'lib/firebase';
import { notiAtom } from 'features/articles/presentation';
import { ArticleConverter } from 'features/articles/domain/Article';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';
import { MessageFormValues } from '../domain/Message';
import { CATEGORY } from 'constants/index';

export const MessageNew = () => {
  const [noti, setNoti] = useAtom(notiAtom);

  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const selectBg = useColorModeValue('white', 'gray.800');
  const modalFooterBg = useColorModeValue('gray.50', 'gray.600');

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, isSubmitting, errors },
  } = useForm<MessageFormValues>({
    mode: 'onChange',
  });

  const isOpen = useMemo(() => !!noti, [noti]);
  const onClose = useCallback(() => setNoti(null), [setNoti]);

  const [value, loading] = useDocumentDataOnce(
    noti ? doc(db, 'articles', noti).withConverter(ArticleConverter) : null
  );

  useEffect(() => {
    if (value) {
      reset({
        // title: `세이버 [${CATEGORY[value.category].text}]`,
        body: value.title,
        articleId: value.id,
        category: value.category,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onSubmit: SubmitHandler<MessageFormValues> = async (data) => {
    try {
      let scheduledAt: dayjs.Dayjs | undefined;
      if (data.dueDate) {
        const now = dayjs();
        scheduledAt = dayjs(
          `${data.dueDate} ${data.dueTime || '10'}:${data.dueMinute || '00'}`
        );
        if (scheduledAt < now) {
          throw Error('예약일시는 현재 이후여야 합니다');
        }
      }

      await addDoc(collection(db, 'messages'), {
        title: data.title,
        body: data.body,
        category: data.category,
        articleId: data.articleId,
        status: scheduledAt ? 'scheduled' : 'immediately',
        ...(scheduledAt && {
          scheduledAt: Timestamp.fromDate(scheduledAt.toDate()),
        }),
        createdAt: serverTimestamp(),
      });

      reset();
      onClose();
      toastSuccess({ description: '새로운 푸시알림을 만들었습니다' });
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent overflow="hidden">
        <ModalHeader>새로운 푸시알림 만들기</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={5}>
          {loading && !value ? (
            <Spinner />
          ) : (
            <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={5}>
                <FormControl>
                  <FormLabel>카테고리</FormLabel>
                  <Input
                    type="text"
                    fontSize="sm"
                    isReadOnly={true}
                    value={value ? CATEGORY[value.category].text : ''}
                  />
                </FormControl>
                <FormControl isInvalid={!!errors.title}>
                  <FormLabel>제목</FormLabel>
                  <Input
                    type="text"
                    fontSize="sm"
                    {...register('title', {
                      required: '필수항목입니다',
                    })}
                  />
                  <FormErrorMessage fontSize="xs">
                    {errors.title && errors.title.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.body}>
                  <FormLabel>내용</FormLabel>
                  <Textarea
                    rows={2}
                    resize="none"
                    fontSize="sm"
                    {...register('body', {
                      required: '필수항목입니다',
                    })}
                  />
                  <FormErrorMessage fontSize="xs">
                    {errors.body && errors.body.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.articleId}>
                  <FormLabel>연결</FormLabel>
                  <Input
                    type="text"
                    fontSize="sm"
                    {...register('articleId', {
                      required: '필수항목입니다',
                    })}
                  />
                  <FormErrorMessage fontSize="xs">
                    {errors.articleId && errors.articleId.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel>예약일시</FormLabel>
                  <Stack spacing="4" direction={{ base: 'column', md: 'row' }}>
                    <FormControl flex={2}>
                      <Input
                        type="date"
                        fontSize="sm"
                        {...register('dueDate')}
                      />
                    </FormControl>
                    <FormControl flex={1}>
                      <Select
                        fontSize="sm"
                        placeholder="시"
                        bg={selectBg}
                        {...register('dueTime')}
                      >
                        {[...Array(12)].map((x, i) => {
                          const h = i + 7;
                          const hour: string = h < 10 ? `0${h}` : `${h}`;
                          return (
                            <option key={`h${i}`} value={hour}>
                              {hour}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl flex={1}>
                      <Select
                        fontSize="sm"
                        placeholder="분"
                        bg={selectBg}
                        {...register('dueMinute')}
                      >
                        <option value="00">00</option>
                        <option value="30">30</option>
                      </Select>
                    </FormControl>
                  </Stack>
                  <FormHelperText fontSize="xs">
                    예약일을 지정하지 않으면 저장 후 1분 이내에 발송됩니다.
                  </FormHelperText>
                </FormControl>
              </Stack>
            </form>
          )}
        </ModalBody>
        <ModalFooter bg={modalFooterBg}>
          <Button
            type="submit"
            form="my-form"
            variant="primary"
            isLoading={isSubmitting}
            disabled={!isDirty && !isValid}
          >
            저장
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
