import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';
import { dateFormat } from 'lib/utils';

export type Summary = {
  id: string;
  status: string;
  articles: string[];
  articlesCount: number;
  dateTime: string;
  createdAt: string;
  updatedAt: string;
};

export const SummaryConverter: FirestoreDataConverter<Summary> = {
  toFirestore(article: WithFieldValue<Summary>): DocumentData {
    return {};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Summary {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      status: data.status,
      articles: data.articles,
      articlesCount: data.articles?.length || 0,
      dateTime: dateFormat(data.dateTime.toDate(), 'YYYY년 M월 D일 Ah') || '-',
      createdAt: dateFormat(data.createdAt?.toDate()) || '-',
      updatedAt: dateFormat(data.updatedAt?.toDate()) || '-',
    };
  },
};
