import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { ColumnDef } from '@tanstack/react-table';
import { query, collection, where } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {
  Button,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import { FiEdit, FiEye, FiTrash2 } from 'react-icons/fi';

import { db } from 'lib/firebase';
import { Word, WordConverter } from '../domain/Word';
import { GenericTable } from 'components/react-table/GenericTable';
import { appUserAtom } from 'index';
import { showAtom, editAtom, removeAtom } from '.';

// const table = createTable().setRowType<Word>();

export const WordsList = () => {
  const [appUser] = useAtom(appUserAtom);
  const [, setShow] = useAtom(showAtom);
  const [, setEdit] = useAtom(editAtom);
  const [, setRemove] = useAtom(removeAtom);

  const isAdmin = useMemo(() => appUser?.role === 'chief', [appUser]);

  const ref = collection(db, 'words');
  const q = isAdmin ? query(ref) : query(ref, where('uid', '==', appUser?.uid));
  const [data, loading] = useCollectionData(
    q.withConverter(WordConverter)
    // { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  // const data: Word[] = useMemo(() => {
  //   if (!value) return [];
  //   return value.map((item) => item);
  // }, [value]);

  const columns: ColumnDef<Word>[] = useMemo(
    () => [
      {
        accessorKey: 'body',
        header: () => '내용',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'editorName',
        header: () => '작성자',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'today',
        cell: (info) => info.getValue(),
        header: () => '게재일',
      },
      {
        accessorKey: 'createdAt',
        cell: (info) => info.getValue(),
        header: () => '등록일시',
      },
      {
        accessorKey: 'actions',
        header: () => '',
        cell: (info) => (
          <HStack spacing="1" justifyContent="flex-end">
            <IconButton
              icon={<FiEye fontSize="1.2rem" />}
              variant="ghost"
              aria-label="View"
              onClick={() => setShow(info.row.original!.id)}
            />
            <IconButton
              icon={<FiEdit fontSize="1rem" />}
              variant="ghost"
              aria-label="Edit"
              onClick={() => setEdit(info.row.original!.id)}
            />
            <IconButton
              icon={<FiTrash2 fontSize="1.2rem" />}
              variant="ghost"
              color="red.300"
              aria-label="Delete"
              onClick={() => setRemove(info.row.original!)}
            />
          </HStack>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Stack spacing={{ base: '8', lg: '6' }}>
      <Stack
        spacing="4"
        direction={{ base: 'column', lg: 'row' }}
        justify="space-between"
        align={{ base: 'start', lg: 'center' }}
      >
        <Stack spacing="1">
          <Heading size={{ base: 'xs', lg: 'sm' }} fontWeight="medium">
            한마디
          </Heading>
        </Stack>
        <HStack spacing="3">
          <Button variant="primary" onClick={() => setEdit('new')}>
            한마디 작성
          </Button>
        </HStack>
      </Stack>

      {loading || !data ? (
        <Spinner />
      ) : (
        <GenericTable<Word>
          name="한마디 목록"
          data={data}
          columns={columns}
          initialSorting={[{ id: 'createdAt', desc: true }]}
        />
      )}
    </Stack>
  );
};
