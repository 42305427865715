import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';
import { dateFormat } from 'lib/utils';

export type Editor = {
  id: string;
  displayName: string;
  email: string;
  role: string;
  createdAt: string;
};

export type EditorFormValues = {
  displayName: string;
  password: string;
  email: string;
  role: string;
};

export const EditorConverter: FirestoreDataConverter<Editor> = {
  toFirestore(article: WithFieldValue<Editor>): DocumentData {
    return {};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Editor {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      displayName: data.displayName,
      email: data.email,
      role: data.role,
      createdAt: dateFormat(data.createdAt?.toDate()) || '-',
    };
  },
};
