import { useEffect, useState } from 'react';
// import { updateDoc, doc, arrayUnion, arrayRemove } from 'firebase/firestore';
import {
  useDisclosure,
  Button,
  CloseButton,
  HStack,
  Stack,
  StackDivider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

// import { db } from 'lib/firebase';
import { Related, RelatedProps } from '../../domain/Related';
import { useToastError } from 'components/start-ui/Toast';
import { typesense } from 'lib/typesense';

export const RelatedInternal = ({ links, setLinks }: RelatedProps) => {
  const [, setProcessing] = useState(false);
  const [query, setQuery] = useState('');
  const [hits, setHits] = useState<{ [key: string]: string }[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toastError = useToastError();

  useEffect(() => {
    if (!onOpen || !query) {
      setQuery('');
      setHits([]);
      return;
    }
    const timeout = setTimeout(() => {
      typesense
        .collections('articles')
        .documents()
        .search({
          q: query,
          query_by: 'title,excerpt,body',
          // filter_by: 'status:published',
          sort_by: 'publishedAt:desc',
          limit_hits: 30,
        })
        .then((results) => {
          const hits =
            results['hits']?.map((hit) => {
              const doc: { [key: string]: string } = hit['document'];
              return {
                url: doc.id,
                title: doc.title,
              };
            }) || [];
          setHits(hits);
        });
    }, 500);
    return () => clearTimeout(timeout);
  }, [query, onOpen]);

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const onSelect = async (i: number) => {
    setProcessing(true);
    try {
      // await updateDoc(doc(db, 'articles', articleId), {
      //   relatedInt: arrayUnion(hits[i]),
      // });
      setLinks((links: Related[]) => [...links, hits[i]]);
      onClose();
      // toastSuccess({ description: '관련 세이버 기사를 등록했습니다' });
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    } finally {
      setProcessing(false);
    }
  };

  const deleteRelated = async (index: number) => {
    setProcessing(true);
    try {
      // await updateDoc(doc(db, 'articles', articleId), {
      //   relatedInt: arrayRemove(links[i]),
      // });
      setLinks((links: Related[]) => links.filter((v, i) => i !== index));
      onClose();
      // toastSuccess({ description: '관련 세이버 기사를 삭제했습니다' });
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button colorScheme="gray" fontSize="sm" onClick={onOpen}>
        관련 세이버 기사 등록
      </Button>

      {links.length > 0 && (
        <Stack divider={<StackDivider />} spacing="1">
          {links.map((link, i) => (
            <HStack key={`int${i}`} justifyContent="space-between">
              <Text fontSize="sm">{link.title}</Text>
              <CloseButton
                size="sm"
                color="muted"
                onClick={() => deleteRelated(i)}
              />
            </HStack>
          ))}
        </Stack>
      )}

      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent overflow="hidden">
          <ModalCloseButton />
          <ModalHeader>관련 세이버 기사 등록</ModalHeader>
          <ModalBody pb={8}>
            <Stack spacing={3}>
              <FormControl>
                <FormLabel>기사 검색</FormLabel>
                <Input type="search" fontSize="sm" onChange={inputHandler} />
              </FormControl>

              {hits.length > 0 && (
                <Stack divider={<StackDivider />} spacing="1">
                  {hits.map((hit, i) => (
                    <HStack
                      key={`hit${i}`}
                      py={2}
                      cursor="pointer"
                      onClick={() => onSelect(i)}
                    >
                      <Text fontSize="sm">{hit.title}</Text>
                    </HStack>
                  ))}
                </Stack>
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
