import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Center, Spinner } from '@chakra-ui/react';

import { auth, db } from 'lib/firebase';
import { appUserAtom } from '.';
import { RequireAuth } from 'components/RequireAuth';
import { NotFound } from 'routes/NotFound';
import { Foobar } from 'routes/Foobar';
import { Home } from 'routes/Home';
import { SignIn } from 'features/authentication/presentation/SignIn';
import { Articles } from 'features/articles/presentation';
import { Words } from 'features/words/presentation';
import { Summaries } from 'features/summaries/presentation';
import { Messages } from 'features/messages/presentation';
import { Quizzes } from 'features/quizzes/presentation';
import { Encashments } from 'features/encashments/presentation';
import { Editors } from 'features/editors/presentation';
import { Docs } from 'features/documents/presentation';
import { MigrationIndex } from 'features/migration';

let unsubscribe: Unsubscribe;

export const App = () => {
  const [fetching, setFetching] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [, setAppUser] = useAtom(appUserAtom);

  useEffect(() => {
    setFetching(true);
    setAppUser(null);
    if (user) {
      unsubscribe = onSnapshot(doc(db, 'editors', user.uid), (doc) => {
        const data = doc.data();
        if (data) {
          setAppUser({
            uid: doc.id,
            email: data.email,
            displayName: data.displayName,
            photoURL: data.photoURL,
            role: data.role,
          });
          setFetching(false);
        }
      });
    } else {
      setFetching(false);
    }

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (loading || fetching) {
    return (
      <Center h="100vh">
        <Spinner size={loading ? 'xl' : 'lg'} />
      </Center>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/foobar" element={<Foobar />} />
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Home />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/words" element={<Words />} />
          <Route path="/summaries" element={<Summaries />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/quizzes" element={<Quizzes />} />
          <Route path="/encashments" element={<Encashments />} />
          <Route path="/editors" element={<Editors />} />
          <Route path="/docs" element={<Docs />} />
          <Route path="/migration" element={<MigrationIndex />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
