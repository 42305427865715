import { atom, Provider } from 'jotai';
import { Editor } from '../domain/Editor';

import { EditorEdit } from './EditorEdit';
import { EditorDelete } from './EditorDelete';
import { EditorsList } from './EditorsList';

const editorScope = Symbol();
export const showAtom = atom<string | null>(null);
export const editAtom = atom<string | null>(null);
export const removeAtom = atom<Editor | null>(null);

export const Editors = () => {
  return (
    <Provider scope={editorScope}>
      <EditorsList />
      <EditorEdit />
      <EditorDelete />
    </Provider>
  );
};
