import dayjs from 'dayjs';
import { useMemo, useCallback, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import {
  deleteField,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadString,
} from 'firebase/storage';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Select,
  Spinner,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';

import { db, storage } from 'lib/firebase';
import { editAtom } from '.';
import { ArticleDetailConverter, ArticleFormValues } from '../domain/Article';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';
import { appUserAtom } from 'index';
import { InputImages } from 'components/InputImages';
import { deletesAtom, imagesAtom } from 'features/upload';
import { TiptapEditor } from 'components/tiptap/Editor';
import { PreviewForEdit } from './preview/PreviewForEdit';
import { HTMLtoJSON, JSONtoHTML } from 'lib/parser';
import { CATEGORY, STATUS } from 'constants/index';
import { RelatedExternal } from './related/RelatedExternal';
import { RelatedInternal } from './related/RelatedInternal';
import { Related } from '../domain/Related';
import { dateFormat } from 'lib/utils';

export const ArticleEdit = () => {
  const [extLinks, setExtLinks] = useState<Related[]>([]);
  const [intLinks, setIntLinks] = useState<Related[]>([]);
  const [appUser] = useAtom(appUserAtom);
  const [edit, setEdit] = useAtom(editAtom);
  const [images, setImages] = useAtom(imagesAtom);
  const [deletes, setDeletes] = useAtom(deletesAtom);

  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const selectBg = useColorModeValue('white', 'gray.800');

  const isAdmin = useMemo(() => appUser?.role === 'chief', [appUser]);
  const availabeStatus = useMemo(() => {
    return isAdmin
      ? Object.keys(STATUS).filter((c) => !['scheduled'].includes(c))
      : Object.keys(STATUS).filter(
          (c) => !['scheduled', 'published'].includes(c)
        );
  }, [isAdmin]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, isSubmitting, errors },
  } = useForm<ArticleFormValues>({
    mode: 'onChange',
  });

  const isOpen = useMemo(() => !!edit, [edit]);
  const onClose = useCallback(() => {
    reset({}, { keepValues: false });
    setImages([]);
    setDeletes([]);
    setEdit(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [value, loading] = useDocumentDataOnce(
    edit
      ? doc(db, 'articles', edit).withConverter(ArticleDetailConverter)
      : null
  );

  useEffect(() => {
    if (value) {
      if (value.title === '헤드라인 미정') value.title = '';
      reset({
        ...value,
        body: HTMLtoJSON(value.body || ''),
        excerpt: HTMLtoJSON(value.excerpt || ''),
      });
      if (value.imageURL) setImages([{ mode: 'url', url: value.imageURL }]);
      setExtLinks(value.relatedExt);
      setIntLinks(value.relatedInt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const deleteImages = async () => {
    for await (const image of deletes) {
      const storageRef = ref(storage, image);
      await deleteObject(storageRef);
    }
    await updateDoc(doc(db, 'articles', edit!), {
      imageURL: deleteField(),
    });
  };

  const uploadImages = async () => {
    const urls: string[] = [];
    for await (const image of images) {
      if (image.mode === 'add') {
        const fileName = dayjs().format('YYYYMMDDHHmmss.SSS');
        const storageRef = ref(storage, `articles/${edit}/${fileName}.jpg`);
        const imageString = image.url.replace('data:image/jpeg;base64,', '');
        const snapshot = await uploadString(storageRef, imageString, 'base64', {
          contentType: 'image/jpeg',
        });
        const downloadURL = await getDownloadURL(snapshot.ref);
        urls.push(downloadURL);
      } else {
        urls.push(image.url);
      }
    }

    return urls;
  };

  const onSubmit: SubmitHandler<ArticleFormValues> = async (data) => {
    try {
      await deleteImages();
      const imageURLs = await uploadImages();

      await setDoc(
        doc(db, 'articles', edit!),
        {
          title: data.title,
          body: JSONtoHTML(data.body),
          excerpt: JSONtoHTML(data.excerpt),
          category: data.category,
          ...(imageURLs.length && { imageURL: imageURLs[0] }),
          imageCaption: data.imageCaption,
          imageSource: data.imageSource,
          updatedAt: serverTimestamp(),
          ...(extLinks.length && { relatedExt: extLinks }),
          ...(intLinks.length && { relatedInt: intLinks }),
          status: data.status,
          ...(data.status === 'published'
            ? {
                publishedAt: value?.publishedAt || serverTimestamp(),
              }
            : { publishedAt: deleteField() }),
        },
        { merge: true }
      );

      onClose();
      toastSuccess({ description: '저장했습니다.' });
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="xl"
      // closeOnEsc={true}
      // closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>기사 편집</DrawerHeader>
        <DrawerBody py="5">
          {loading || !value ? (
            <Spinner />
          ) : (
            <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
              <Grid templateColumns="repeat(5, 1fr)" gap={5}>
                <GridItem colSpan={{ base: 5, lg: 3 }}>
                  <Stack spacing="5">
                    <FormControl isInvalid={!!errors.title}>
                      <FormLabel>헤드라인</FormLabel>
                      <Input
                        type="text"
                        fontSize="sm"
                        {...register('title', {
                          required: '필수항목입니다',
                        })}
                      />
                      <FormErrorMessage fontSize="xs">
                        {errors.title && errors.title.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl>
                      <FormLabel>100자 브리핑</FormLabel>
                      <Controller
                        name="excerpt"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TiptapEditor
                            height={200}
                            mini={true}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>내용</FormLabel>
                      <Controller
                        name="body"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TiptapEditor
                            height={500}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      <FormHelperText color="subtle">
                        기사 상세페이지에 보여지는 내용
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </GridItem>
                <GridItem colSpan={{ base: 5, lg: 2 }}>
                  <Stack spacing="5">
                    <FormControl>
                      <FormLabel>사진</FormLabel>
                      <Controller
                        control={control}
                        name="imageURL"
                        // rules={{
                        //   validate: (v) => (v > 0 ? true : '필수항목입니다'),
                        // }}
                        render={({ field: { onChange, value } }) => (
                          <InputImages
                            onChange={(value: number) => onChange(value)}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>사진 설명</FormLabel>
                      <Textarea
                        rows={2}
                        resize="none"
                        fontSize="sm"
                        {...register('imageCaption')}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>사진 출처</FormLabel>
                      <Input
                        type="text"
                        fontSize="sm"
                        {...register('imageSource')}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.category}>
                      <FormLabel>카테고리</FormLabel>
                      <Select
                        fontSize="sm"
                        placeholder="선택하세요"
                        bg={selectBg}
                        {...register('category', {
                          required: '필수항목입니다',
                        })}
                      >
                        {Object.keys(CATEGORY).map((value) => (
                          <option key={value} value={value}>
                            {CATEGORY[value].text}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage fontSize="xs">
                        {errors.category && errors.category.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.status}>
                      <FormLabel>상태</FormLabel>
                      <Select
                        fontSize="sm"
                        placeholder="선택하세요"
                        bg={selectBg}
                        {...register('status', {
                          required: '필수항목입니다',
                        })}
                      >
                        {availabeStatus.map((value) => (
                          <option key={value} value={value}>
                            {STATUS[value].text}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage fontSize="xs">
                        {errors.status && errors.status.message}
                      </FormErrorMessage>
                    </FormControl>
                    {value?.publishedAt && (
                      <HStack>
                        <Text fontSize="sm">게재일시:</Text>
                        <Text fontSize="sm" color="muted">
                          {dateFormat(value?.publishedAt?.toDate())}
                        </Text>
                      </HStack>
                    )}
                    <RelatedExternal links={extLinks} setLinks={setExtLinks} />
                    <Divider />
                    <RelatedInternal links={intLinks} setLinks={setIntLinks} />
                  </Stack>
                </GridItem>
              </Grid>
            </form>
          )}
        </DrawerBody>
        <DrawerFooter
          bg={useColorModeValue('gray.50', 'gray.600')}
          justifyContent="space-between"
        >
          <PreviewForEdit
            control={control}
            relatedExt={extLinks}
            relatedInt={intLinks}
          />
          <Button
            type="submit"
            form="my-form"
            variant="primary"
            isLoading={isSubmitting}
            disabled={!isDirty || !isValid}
          >
            저장
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
