import { atom, Provider } from 'jotai';

import { Word } from '../domain/Word';
import { WordShow } from './WordShow';
import { WordEdit } from './WordEdit';
import { WordDelete } from './WordDelete';
import { WordsList } from './WordsList';

const wordScope = Symbol();
export const showAtom = atom<string | null>(null);
export const editAtom = atom<string | null>(null);
export const removeAtom = atom<Word | null>(null);

export const Words = () => {
  return (
    <Provider scope={wordScope}>
      <WordsList />
      <WordShow />
      <WordEdit />
      <WordDelete />
    </Provider>
  );
};
