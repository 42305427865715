import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { ColumnDef } from '@tanstack/react-table';
import { query, collection } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {
  Button,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';

import { db } from 'lib/firebase';
import { Editor, EditorConverter } from '../domain/Editor';
import { GenericTable } from 'components/react-table/GenericTable';
import { editAtom, removeAtom } from '.';
import { EDITORS } from 'constants/index';

const RoleText = ({ role }: { role: string }) => {
  const thisRole = EDITORS[role];
  return !thisRole ? <>{role}</> : <>{thisRole.text}</>;
};

export const EditorsList = () => {
  const [, setEdit] = useAtom(editAtom);
  const [, setRemove] = useAtom(removeAtom);

  const [data, loading] = useCollectionData(
    query(collection(db, 'editors')).withConverter(EditorConverter)
  );

  const columns: ColumnDef<Editor>[] = useMemo(
    () => [
      {
        accessorKey: 'displayName',
        header: () => '이름',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'email',
        cell: (info) => info.getValue(),
        header: () => '이메일',
      },
      {
        accessorKey: 'role',
        header: () => '역할',
        cell: (info) => <RoleText role={info.getValue() as string} />,
      },
      {
        accessorKey: 'createdAt',
        cell: (info) => info.getValue(),
        header: () => '등록일시',
      },
      {
        accessorKey: 'actions',
        header: () => '',
        cell: (info) => (
          <HStack spacing="1" justifyContent="flex-end">
            <IconButton
              icon={<FiEdit fontSize="1rem" />}
              variant="ghost"
              aria-label="Edit"
              onClick={() => setEdit(info.row.original!.id)}
            />
            <IconButton
              icon={<FiTrash2 fontSize="1.2rem" />}
              variant="ghost"
              color="red.300"
              aria-label="Delete"
              onClick={() => setRemove(info.row.original!)}
            />
          </HStack>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Stack spacing={{ base: '8', lg: '6' }}>
      <Stack
        spacing="4"
        direction={{ base: 'column', lg: 'row' }}
        justify="space-between"
        align={{ base: 'start', lg: 'center' }}
      >
        <Stack spacing="1">
          <Heading size={{ base: 'xs', lg: 'sm' }} fontWeight="medium">
            필자
          </Heading>
        </Stack>
        <HStack spacing="3">
          <Button variant="primary" onClick={() => setEdit('new')}>
            필자 등록
          </Button>
        </HStack>
      </Stack>

      {loading || !data ? (
        <Spinner />
      ) : (
        <GenericTable<Editor>
          name="필자 목록"
          data={data}
          columns={columns}
          initialSorting={[{ id: 'createdAt', desc: true }]}
        />
      )}
    </Stack>
  );
};
