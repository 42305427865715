import { useMemo, useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';
import { doc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  Alert,
  AlertIcon,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spinner,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';

import { db, functions } from 'lib/firebase';
import { editAtom } from '.';
import { EditorConverter, EditorFormValues } from '../domain/Editor';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';
import { EDITORS } from 'constants/index';

const collectionName = 'editors';

export const EditorEdit = () => {
  const [edit, setEdit] = useAtom(editAtom);

  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const selectBg = useColorModeValue('white', 'gray.800');

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, isSubmitting, errors },
  } = useForm<EditorFormValues>({
    mode: 'onChange',
  });

  const isOpen = useMemo(() => !!edit, [edit]);
  const onClose = useCallback(() => {
    reset({}, { keepValues: false });
    setEdit(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [value, loading] = useDocumentDataOnce(
    edit && edit !== 'new'
      ? doc(db, collectionName, edit).withConverter(EditorConverter)
      : null
  );

  useEffect(() => {
    if (value) reset(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onSubmit: SubmitHandler<EditorFormValues> = async (data) => {
    try {
      if (edit === 'new') {
        await httpsCallable(
          functions,
          'createEditor'
        )({
          email: data.email,
          password: data.password,
          displayName: data.displayName,
          role: data.role,
        });
      } else {
        await httpsCallable(
          functions,
          'updateEditor'
        )({
          uid: edit,
          password: data.password,
        });
      }
      onClose();
      toastSuccess({ description: '저장했습니다.' });
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>필자 {edit === 'new' ? '등록' : '수정'}</DrawerHeader>
        <DrawerBody py="5">
          {loading && !value ? (
            <Spinner />
          ) : (
            <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing="5">
                {edit !== 'new' && (
                  <Alert status="warning" fontSize="sm">
                    <AlertIcon />
                    비밀번호 수정만 가능합니다!
                  </Alert>
                )}
                <FormControl isInvalid={!!errors.displayName}>
                  <FormLabel>이름</FormLabel>
                  <Input
                    fontSize="sm"
                    {...register('displayName', {
                      required: '필수항목입니다',
                    })}
                    isReadOnly={edit !== 'new'}
                  />
                  <FormErrorMessage fontSize="xs">
                    {errors.displayName && errors.displayName.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.email}>
                  <FormLabel>이메일</FormLabel>
                  <Input
                    fontSize="sm"
                    {...register('email', {
                      required: '필수항목입니다',
                    })}
                    isReadOnly={edit !== 'new'}
                  />
                  <FormErrorMessage fontSize="xs">
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.password}>
                  <FormLabel>비밀번호</FormLabel>
                  <Input
                    fontSize="sm"
                    {...register('password', {
                      required: '필수항목입니다',
                      minLength: {
                        value: 6,
                        message: '6자 이상 입력해 주세요',
                      },
                    })}
                  />
                  <FormErrorMessage fontSize="xs">
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.role}>
                  <FormLabel>역할</FormLabel>
                  <Select
                    fontSize="sm"
                    placeholder="선택하세요"
                    bg={selectBg}
                    {...register('role', {
                      required: '필수항목입니다',
                    })}
                    isDisabled={edit !== 'new'}
                  >
                    {Object.keys(EDITORS).map((value) => (
                      <option key={value} value={value}>
                        {EDITORS[value].text}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage fontSize="xs">
                    {errors.role && errors.role.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </form>
          )}
        </DrawerBody>
        <DrawerFooter bg={useColorModeValue('gray.50', 'gray.600')}>
          <Button
            type="submit"
            form="my-form"
            variant="primary"
            isLoading={isSubmitting}
            disabled={!isDirty || !isValid}
          >
            저장
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
