import dayjs from 'dayjs';

export const numberFormat = (number: number | null, returnText?: string) => {
  if (!number) return returnText ?? null;
  return new Intl.NumberFormat().format(number);
};

export const phoneNumberFormat = (
  number: string | null | undefined,
  returnText?: string
) => {
  if (!number) return returnText ?? null;
  return number.replace(
    /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
    '$1-$2-$3'
  );
};

export const dateFormat = (
  date: Date | string | null | undefined,
  format: string = 'YYYY-MM-DD HH:mm',
  returnText?: string
) => {
  if (!date) return returnText ?? null;

  return dayjs(date).format(format);
};

export const resetStart = (dayjs: dayjs.Dayjs) => {
  return dayjs
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .toDate();
};

export const resetEnd = (dayjs: dayjs.Dayjs) => {
  return dayjs
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .set('millisecond', 999)
    .toDate();
};
