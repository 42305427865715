import { useMemo, useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Spinner,
  Stack,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';

import { db } from 'lib/firebase';
import { editAtom } from '.';
import { WordConverter, WordFormValues } from '../domain/Word';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';
import { appUserAtom } from 'index';

const collectionName = 'words';

export const WordEdit = () => {
  const [appUser] = useAtom(appUserAtom);
  const [edit, setEdit] = useAtom(editAtom);

  const toastSuccess = useToastSuccess();
  const toastError = useToastError();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, isSubmitting, errors },
  } = useForm<WordFormValues>({
    mode: 'onChange',
  });

  const isOpen = useMemo(() => !!edit, [edit]);
  const onClose = useCallback(() => {
    reset({}, { keepValues: false });
    setEdit(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [value, loading] = useDocumentDataOnce(
    edit && edit !== 'new'
      ? doc(db, collectionName, edit).withConverter(WordConverter)
      : null
  );

  useEffect(() => {
    if (value) reset(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onSubmit: SubmitHandler<WordFormValues> = async (data) => {
    try {
      if (edit === 'new') {
        await addDoc(collection(db, collectionName), {
          uid: appUser?.uid,
          editorName: appUser?.displayName,
          body: data.body,
          createdAt: serverTimestamp(),
        });
      } else {
        await setDoc(
          doc(db, collectionName, edit!),
          {
            body: data.body,
            updatedAt: serverTimestamp(),
          },
          { merge: true }
        );
      }
      onClose();
      toastSuccess({ description: '저장했습니다.' });
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>한마디 {edit === 'new' ? '등록' : '수정'}</DrawerHeader>
        <DrawerBody py="5">
          {loading && !value ? (
            <Spinner />
          ) : (
            <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing="5">
                <FormControl isInvalid={!!errors.body}>
                  <FormLabel>내용</FormLabel>
                  <Textarea
                    rows={3}
                    resize="none"
                    fontSize="sm"
                    {...register('body', {
                      required: '필수항목입니다',
                      // maxLength: {
                      //   value: 100,
                      //   message: '100자를 넘을 수 없습니다',
                      // },
                    })}
                  />
                  {/* <FormHelperText color="subtle">100자 이내</FormHelperText> */}
                  <FormErrorMessage fontSize="xs">
                    {errors.body && errors.body.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </form>
          )}
        </DrawerBody>
        <DrawerFooter bg={useColorModeValue('gray.50', 'gray.600')}>
          <Button
            type="submit"
            form="my-form"
            variant="primary"
            isLoading={isSubmitting}
            disabled={!isDirty || !isValid}
          >
            저장
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
