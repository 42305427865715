import { useState } from 'react';
import { useForm } from 'react-hook-form';
// import { updateDoc, doc, arrayUnion, arrayRemove } from 'firebase/firestore';
import {
  Button,
  Stack,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  FormErrorMessage,
  ModalFooter,
  useColorModeValue,
  Link,
  CloseButton,
  StackDivider,
  HStack,
} from '@chakra-ui/react';

// import { db } from 'lib/firebase';
import { Related, RelatedProps, RelatedFormValues } from '../../domain/Related';
import { useToastError } from 'components/start-ui/Toast';

export const RelatedExternal = ({ links, setLinks }: RelatedProps) => {
  const [processing, setProcessing] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalFooterBg = useColorModeValue('gray.50', 'gray.600');

  const toastError = useToastError();

  const {
    register,
    getValues,
    reset,
    formState: { isValid, isDirty, errors },
  } = useForm<RelatedFormValues>({
    mode: 'onChange',
  });

  const onSubmit = async () => {
    setProcessing(true);
    const { title, url } = getValues();
    try {
      // await updateDoc(doc(db, 'articles', articleId), {
      //   relatedExt: arrayUnion({ title, url }),
      // });
      setLinks((links: Related[]) => [...links, { title, url }]);
      reset();
      onClose();
      // toastSuccess({ description: '관련 외부 기사를 등록했습니다' });
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    } finally {
      setProcessing(false);
    }
  };

  const deleteRelated = async (index: number) => {
    setProcessing(true);
    try {
      // await updateDoc(doc(db, 'articles', articleId), {
      //   relatedExt: arrayRemove(links[i]),
      // });
      setLinks((links: Related[]) => links.filter((v, i) => i !== index));
      onClose();
      // toastSuccess({ description: '관련 외부 기사를 삭제했습니다' });
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button colorScheme="gray" fontSize="sm" onClick={onOpen}>
        관련 외부 기사 등록
      </Button>

      {links.length > 0 && (
        <Stack divider={<StackDivider />} spacing="1">
          {links.map((link, i) => (
            <HStack key={`ext${i}`} justifyContent="space-between">
              <Link href={link.url} fontSize="sm" isExternal>
                {link.title}
              </Link>
              <CloseButton
                size="sm"
                color="muted"
                onClick={() => deleteRelated(i)}
              />
            </HStack>
          ))}
        </Stack>
      )}

      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent overflow="hidden">
          <ModalCloseButton />
          <ModalHeader>관련 외부 기사 등록</ModalHeader>
          <ModalBody pb={5}>
            <form id="ext-form">
              <Stack spacing="5">
                <FormControl isInvalid={!!errors.title}>
                  <FormLabel>제목</FormLabel>
                  <Input
                    type="text"
                    fontSize="sm"
                    {...register('title', {
                      required: '필수항목입니다',
                    })}
                  />
                  <FormErrorMessage fontSize="xs">
                    {errors.title && errors.title.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.url}>
                  <FormLabel>URL</FormLabel>
                  <Input
                    type="text"
                    fontSize="sm"
                    {...register('url', {
                      required: '필수항목입니다',
                    })}
                  />
                  <FormErrorMessage fontSize="xs">
                    {errors.url && errors.url.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </form>
          </ModalBody>
          <ModalFooter bg={modalFooterBg}>
            <Button
              type="button"
              form="ext-form"
              variant="primary"
              isLoading={processing}
              disabled={!isDirty || !isValid}
              onClick={() => onSubmit()}
            >
              등록
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
