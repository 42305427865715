import {
  HStack,
  ButtonGroup,
  IconButton,
  Button,
  Stack,
} from '@chakra-ui/react';
import { Editor } from '@tiptap/react';
import {
  AiOutlineOrderedList,
  AiOutlineStrikethrough,
  AiOutlineUnorderedList,
} from 'react-icons/ai';
import {
  FiCode,
  FiRotateCcw,
  FiRotateCw,
  FiImage,
  FiYoutube,
  FiBold,
  FiItalic,
  FiUnderline,
  FiAlignCenter,
  FiAlignLeft,
  FiAlignRight,
  FiLink,
} from 'react-icons/fi';
import { BiParagraph } from 'react-icons/bi';
import { BsTextIndentLeft, BsTextIndentRight } from 'react-icons/bs';
import { useCallback, useEffect, useState } from 'react';
import { EditorModal } from './EditorModal';

type Props = {
  editor: Editor | null;
  mini?: boolean;
  collection?: string;
};

export const MenuBar = ({ editor, mini, collection }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    if (!editor || modalOpen) return;
    if (modalType === 'link') {
      if (value === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
      } else {
        editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: value })
          .run();
      }
    } else if (modalType === 'youtube') {
      if (value === '') return;
      editor.commands.setYoutubeVideo({
        src: value,
        width: 640,
        height: 320,
      });
    } else if (modalType === 'image') {
      if (value === '') return;
      editor.chain().focus().setImage({ src: value }).run();
    }
  }, [editor, modalOpen, modalType, value]);

  const setLink = useCallback(() => {
    if (!editor) return;
    const previousUrl = editor.getAttributes('link').href;
    setModalType('link');
    setModalOpen(true);
    setValue(previousUrl || '');
  }, [editor]);

  const addYoutubeVideo = useCallback(() => {
    if (!editor) return;
    setValue('');
    setModalType('youtube');
    setModalOpen(true);
  }, [editor]);

  const addImage = useCallback(() => {
    if (!editor) return;
    setValue('');
    setModalType('image');
    setModalOpen(true);
  }, [editor]);

  if (!editor) return null;

  return (
    <>
      <Stack spacing={1} px={3} pb={1}>
        <HStack spacing={1} px={1} mt={1}>
          <ButtonGroup isAttached variant="outline" size="sm">
            <IconButton
              onClick={() => editor.chain().focus().undo().run()}
              aria-label="undo"
              icon={<FiRotateCcw />}
            />
            <IconButton
              onClick={() => editor.chain().focus().redo().run()}
              aria-label="redo"
              icon={<FiRotateCw />}
            />
          </ButtonGroup>
          <ButtonGroup isAttached variant="outline" size="sm">
            <IconButton
              aria-label="bold"
              icon={<FiBold />}
              isActive={editor.isActive('bold')}
              onClick={() => editor.chain().focus().toggleBold().run()}
            />
            <IconButton
              aria-label="italic"
              icon={<FiItalic />}
              isActive={editor.isActive('italic')}
              onClick={() => editor.chain().focus().toggleItalic().run()}
            />
            <IconButton
              aria-label="italic"
              icon={<FiUnderline />}
              isActive={editor.isActive('underline')}
              onClick={() => editor.chain().focus().toggleUnderline().run()}
            />
            <IconButton
              aria-label="strike"
              icon={<AiOutlineStrikethrough />}
              isActive={editor.isActive('strike')}
              onClick={() => editor.chain().focus().toggleStrike().run()}
            />
          </ButtonGroup>
          <ButtonGroup isAttached variant="outline" size="sm">
            <Button
              aria-label="h1"
              px={2}
              isActive={editor.isActive('heading', { level: 1 })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
            >
              H1
            </Button>
            <Button
              aria-label="h2"
              px={2}
              isActive={editor.isActive('heading', { level: 2 })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
            >
              H2
            </Button>
            <Button
              aria-label="h3"
              px={2}
              isActive={editor.isActive('heading', { level: 3 })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
            >
              H3
            </Button>
            <IconButton
              aria-label="p"
              icon={<BiParagraph />}
              onClick={() => editor.chain().focus().setParagraph().run()}
            />
          </ButtonGroup>
          <IconButton
            variant="outline"
            size="sm"
            aria-label="code"
            icon={<FiCode />}
            isActive={editor.isActive('codeBlock')}
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          />
        </HStack>
        {!mini && (
          <HStack spacing={1} px={1} my={1}>
            <ButtonGroup isAttached variant="outline" size="sm">
              <IconButton
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                isActive={editor.isActive('bulletList')}
                aria-label="bulletList"
                icon={<AiOutlineUnorderedList />}
              />
              <IconButton
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                isActive={editor.isActive('orderedList')}
                aria-label="orderedList"
                icon={<AiOutlineOrderedList />}
              />
            </ButtonGroup>
            <ButtonGroup isAttached variant="outline" size="sm">
              <IconButton
                aria-label="left"
                isActive={editor.isActive({ textAlign: 'left' })}
                icon={<FiAlignLeft />}
                onClick={() =>
                  editor.chain().focus().setTextAlign('left').run()
                }
              />
              <IconButton
                aria-label="center"
                isActive={editor.isActive({ textAlign: 'center' })}
                icon={<FiAlignCenter />}
                onClick={() =>
                  editor.chain().focus().setTextAlign('center').run()
                }
              />
              <IconButton
                aria-label="right"
                isActive={editor.isActive({ textAlign: 'right' })}
                icon={<FiAlignRight />}
                onClick={() =>
                  editor.chain().focus().setTextAlign('right').run()
                }
              />
            </ButtonGroup>
            <ButtonGroup isAttached variant="outline" size="sm">
              <IconButton
                aria-label="indent"
                icon={<BsTextIndentLeft />}
                onClick={() => editor.chain().focus().increaseIndent().run()}
              />
              <IconButton
                aria-label="unindent"
                icon={<BsTextIndentRight />}
                onClick={() => editor.chain().focus().decreaseIndent().run()}
              />
            </ButtonGroup>
            <ButtonGroup isAttached variant="outline" size="sm">
              <IconButton
                aria-label="link"
                icon={<FiLink />}
                onClick={setLink}
              />
              <IconButton
                onClick={addImage}
                aria-label="image"
                icon={<FiImage />}
              />
              <IconButton
                onClick={addYoutubeVideo}
                aria-label="video"
                icon={<FiYoutube />}
              />
            </ButtonGroup>
          </HStack>
        )}
      </Stack>
      <EditorModal
        type={modalType}
        collection={collection}
        open={modalOpen}
        setOpen={setModalOpen}
        value={value}
        setValue={setValue}
      />
    </>
  );
};
