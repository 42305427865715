import { Flex, Heading, Link, Stack } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export const NotFound = () => {
  return (
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <Stack spacing="8" alignItems="center">
        <Heading as="h5" size="sm">
          404 - 페이지를 찾을 수 없습니다!
        </Heading>
        <Link as={RouterLink} to="/">
          홈으로
        </Link>
      </Stack>
    </Flex>
  );
};
