import { useEffect, useCallback } from 'react';
import { EditorContent, JSONContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import TextAlign from '@tiptap/extension-text-align';
import Youtube from '@tiptap/extension-youtube';
import { MarginLeft } from './extensions/text-styles';
import { Indent } from './extensions/indent';
import { Box, useColorModeValue } from '@chakra-ui/react';

import { MenuBar } from './MenuBar';

type Props = {
  height?: number;
  mini?: boolean;
  collection?: string;
  value: JSONContent;
  onChange: (body: JSONContent) => void;
};

export const TiptapEditor = ({
  height,
  mini,
  collection,
  value,
  onChange,
}: Props) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const editor = useEditor({
    extensions: [
      StarterKit,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Underline,
      MarginLeft,
      Indent,
      Link,
      Image,
      Youtube,
    ],
    editorProps: {
      attributes: {
        spellcheck: 'false',
        class: 'chakra-textarea',
      },
      // transformPastedHTML(html) {
      //   return html.replace(/\xA0/g, '');
      // },
    },
  });

  useEffect(() => {
    if (!editor) {
      return undefined;
    }

    setContent();

    onChange(editor.getJSON());

    editor.on('update', () => {
      onChange(editor.getJSON());
    });

    return () => {
      editor.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  const setContent = useCallback(() => {
    if (!editor || !value) return;

    editor.commands.setContent(value, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  if (!editor) return <></>;

  return (
    <Box
      border="1px"
      borderRadius="lg"
      borderColor="inherit"
      bg={bgColor}
      overflow="hidden"
    >
      <MenuBar editor={editor} mini={mini} collection={collection} />
      <Box overflow="auto">
        <EditorContent editor={editor} />
      </Box>
    </Box>
  );
};
