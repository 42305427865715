type Category = { [index: string]: { text: string } };

type Status = {
  [index: string]: { text: string; color: string };
};

const CATEGORY: Category = {
  economy: { text: '경제' },
  politics: { text: '정치' },
  international: { text: '국제' },
  social: { text: '사회' },
  culture: { text: '문화' },
};

const PUSH_CATEGORY: Category = {
  event: { text: '이벤트' },
  ...CATEGORY,
};

const STATUS: Status = {
  draft: { text: '작성중', color: 'gray' },
  completed: { text: '작성완료', color: 'blue' },
  released: { text: '출고', color: 'orange' },
  scheduled: { text: '예약', color: 'purple' },
  published: { text: '게재', color: 'green' },
};

const PUSH_STATUS: Status = {
  immediately: { text: '즉시발송', color: 'gray' },
  scheduled: { text: '예약', color: 'purple' },
  completed: { text: '완료', color: 'green' },
  failed: { text: '실패', color: 'red' },
};

const AMPM_STATUS: Status = {
  pending: { text: '대기', color: 'gray' },
  completed: { text: '완료', color: 'green' },
};

const ENCASH_STATUS: Status = {
  requested: { text: '지급신청', color: 'gray' },
  rejected: { text: '반려', color: 'red' },
  approved: { text: '승인/지급대기', color: 'blue' },
  completed: { text: '지급완료', color: 'green' },
};

const EDITORS: Category = {
  admin: { text: '관리자' },
  chief: { text: '수석에디터' },
  desk: { text: '데스크' },
  reporter: { text: '리포터' },
  writer: { text: '외부필진' },
};

export {
  CATEGORY,
  PUSH_CATEGORY,
  STATUS,
  AMPM_STATUS,
  PUSH_STATUS,
  ENCASH_STATUS,
  EDITORS,
};
