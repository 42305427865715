import { atom, Provider } from 'jotai';
import { Doc } from '../domain/Doc';

import { DocEdit } from './DocEdit';
import { DocDelete } from './DocDelete';
import { DocsList } from './DocsList';

const docScope = Symbol();
export const showAtom = atom<string | null>(null);
export const editAtom = atom<string | null>(null);
export const removeAtom = atom<Doc | null>(null);

export const Docs = () => {
  return (
    <Provider scope={docScope}>
      <DocsList />
      <DocEdit />
      <DocDelete />
    </Provider>
  );
};
