import { atom, Provider } from 'jotai';

import { Summary } from '../domain/Summary';
import { SummariesList } from './SummariesList';
import { SummaryEdit } from './SummaryEdit';
import { SummaryDelete } from './SummaryDelete';

const summaryScope = Symbol();
export const editAtom = atom<string | null>(null);
export const removeAtom = atom<Summary | null>(null);

export const Summaries = () => {
  return (
    <Provider scope={summaryScope}>
      <SummariesList />
      <SummaryEdit />
      <SummaryDelete />
    </Provider>
  );
};
