import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { auth } from 'lib/firebase';
import { Layout } from './layouts';
import { appUserAtom } from '..';
import { signOut } from 'firebase/auth';

export const RequireAuth = () => {
  const [appUser] = useAtom(appUserAtom);
  const location = useLocation();

  useEffect(() => {
    if (appUser && !appUser.role) {
      signOut(auth);
    }
  }, [appUser]);

  if (!appUser) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
