import { atom, Provider } from 'jotai';

import { Quiz } from '../domain/Quiz';
import { QuizzesList } from './QuizzesList';
import { QuizEdit } from './QuizEdit';
import { QuizDelete } from './QuizDelete';

const quizScope = Symbol();
export const editAtom = atom<string | null>(null);
export const removeAtom = atom<Quiz | null>(null);

export const Quizzes = () => {
  return (
    <Provider scope={quizScope}>
      <QuizzesList />
      <QuizEdit />
      <QuizDelete />
    </Provider>
  );
};
