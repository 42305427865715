import { generateHTML, generateJSON, JSONContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import TextAlign from '@tiptap/extension-text-align';
import Youtube from '@tiptap/extension-youtube';
import { MarginLeft } from 'components/tiptap/extensions/text-styles';
import { Indent } from 'components/tiptap/extensions/indent';

export const JSONtoHTML = (json: JSONContent) => {
  if (!json) return '<p></p>';

  const html = generateHTML(json, [
    StarterKit,
    Underline,
    TextAlign,
    MarginLeft,
    Indent,
    Link,
    Image,
    Youtube,
  ]);

  return html.replaceAll('<p></p>', '<p>&nbsp;</p>');
};

export const HTMLtoJSON = (html: string) => {
  return generateJSON(html, [
    StarterKit,
    Underline,
    TextAlign,
    MarginLeft,
    Indent,
    Link,
    Image,
    Youtube,
  ]);
};
