import { SetStateAction, useCallback } from 'react';
import { Stack } from '@chakra-ui/react';
import update from 'immutability-helper';
import type { FC } from 'react';

import { DragCard } from './DragCard';

export interface Item {
  id: string;
  text: string;
  correct?: boolean;
}

export interface ContainerProps {
  cards: Item[];
  setCards: (value: SetStateAction<Item[]>) => void;
}

export const DragContainer: FC<ContainerProps> = ({ cards, setCards }) => {
  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: Item[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Item],
        ],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCard = useCallback(
    (card: Item, index: number) => {
      return (
        <DragCard
          key={card.id}
          index={index}
          id={card.id}
          text={card.text + (card.correct ? ' [정답]' : '')}
          moveCard={moveCard}
          removeCard={removeCard}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const removeCard = useCallback((index: number) => {
    setCards((prevCards: Item[]) => prevCards.filter((_, i) => i !== index));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={2}>{cards.map((card, i) => renderCard(card, i))}</Stack>
  );
};
