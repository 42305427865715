import { JSONContent } from '@tiptap/react';
import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from 'firebase/firestore';
import { dateFormat } from 'lib/utils';
import { Related } from './Related';

export type Article = {
  id: string;
  title: string;
  category: string;
  status: string;
  today?: string;
  editorName: string;
  imageURL?: string;
  images: string[];
  createdAt: string;
};

export type ArticleDetail = {
  id: string;
  title: string;
  body?: string;
  excerpt?: string;
  category: string;
  status: string;
  imageURL?: string;
  imageCaption?: string;
  imageSource?: string;
  today?: string;
  editorName: string;
  editorEmail: string;
  relatedExt: Related[];
  relatedInt: Related[];
  dueDate?: string;
  dueTime?: string;
  dueMinute?: string;
  scheduledAt?: Date;
  publishedAt?: Timestamp;
};

export type ArticleFormValues = {
  title: string;
  body: JSONContent;
  excerpt: JSONContent;
  category: string;
  status: string;
  imageURL?: string;
  imageCaption?: string;
  imageSource?: string;
};

export type ReserveFormValues = {
  dueDate?: string;
  dueTime?: string;
  dueMinute?: string;
};

export type Preview = {
  title: string | null;
  body?: string;
  excerpt?: string;
  image?: string | null;
  caption?: string | null;
  source?: string | null;
  relatedExt: Related[];
  relatedInt: Related[];
  publishedAt?: Timestamp;
};

export const ArticleConverter: FirestoreDataConverter<Article> = {
  toFirestore(article: WithFieldValue<Article>): DocumentData {
    return {};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Article {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      title: data.title,
      category: data.category,
      status: data.status,
      editorName: data.editorName,
      imageURL: data.imageURL,
      images: data.images || [],
      today: dateFormat(data.today?.toDate(), 'YYYY-MM-DD') || undefined,
      createdAt: dateFormat(data.createdAt?.toDate()) || '-',
    };
  },
};

export const ArticleDetailConverter: FirestoreDataConverter<ArticleDetail> = {
  toFirestore(article: WithFieldValue<ArticleDetail>): DocumentData {
    return {};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ArticleDetail {
    const data = snapshot.data(options);

    let dueDate = '',
      dueTime = '',
      dueMinute = '';
    if (data.scheduledAt) {
      const scheduledAt = data.scheduledAt.toDate();
      dueDate = dateFormat(scheduledAt, 'YYYY-MM-DD') || '';
      dueTime = dateFormat(scheduledAt, 'HH') || '';
      dueMinute = dateFormat(scheduledAt, 'mm') || '';
    }

    return {
      id: snapshot.id,
      title: data.title,
      body: data.body,
      excerpt: data.excerpt,
      category: data.category,
      status: data.status,
      imageURL: data.imageURL,
      imageCaption: data.imageCaption,
      imageSource: data.imageSource,
      editorName: data.editorName,
      editorEmail: data.editorEmail,
      today: dateFormat(data.today?.toDate(), 'YYYY-MM-DD') || undefined,
      relatedExt: data.relatedExt || [],
      relatedInt: data.relatedInt || [],
      ...(dueDate && { dueDate }),
      ...(dueTime && { dueTime }),
      ...(dueMinute && { dueMinute }),
      scheduledAt: data.scheduledAt?.toDate() || undefined,
      publishedAt: data.publishedAt,
    };
  },
};
