import { useCallback, useMemo, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { deleteDoc, doc } from 'firebase/firestore';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react';

import { db } from 'lib/firebase';
import { removeAtom } from '.';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';

export const MessageDelete = () => {
  const [processing, setProcessing] = useState(false);
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  const toastSuccess = useToastSuccess();
  const toastError = useToastError();

  const [remove, setRemove] = useAtom(removeAtom);

  const isOpen = useMemo(() => !!remove, [remove]);
  const onClose = useCallback(() => setRemove(null), [setRemove]);

  const onDelete = async () => {
    if (!remove) return;
    setProcessing(true);
    try {
      await deleteDoc(doc(db, 'messages', remove.id));

      onClose();
      toastSuccess({ description: '삭제했습니다.' });
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            푸시알림 삭제
          </AlertDialogHeader>

          <AlertDialogBody>
            정말 삭제할까요? 복구할 수 없습니다.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} isDisabled={processing}>
              취소
            </Button>
            <Button
              colorScheme="red"
              onClick={onDelete}
              ml={3}
              isLoading={processing}
            >
              삭제합니다!
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
