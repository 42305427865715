import { chakra, HTMLChakraProps, useColorModeValue } from '@chakra-ui/react';

export const Logo = (props: HTMLChakraProps<'svg'>) => {
  const color = useColorModeValue('gray.900', 'white');
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      height="5"
      width="auto"
      viewBox="0 0 89 15.148"
    >
      <g opacity="0.9">
        <path
          fill={color}
          d="M264.171 284.112l-4.495 11.031-.025.068a1.451 1.451 0 0 1-1.382.977h-1.927a1.452 1.452 0 0 1-1.382-.977l-4.52-11.1h-2.572l4.848 11.9a3.833 3.833 0 0 0 3.627 2.557h1.927a3.832 3.832 0 0 0 3.626-2.557l4.848-11.9z"
          transform="translate(-173.992 -284.082) translate(-36.614 -.015)"
        />
        <path
          fill={color}
          d="M288.027 292.518H299.1v-2.379H289l1.165-2.876c.073-.194.3-.786 1.343-.786h7.584V284.1H291.5a3.624 3.624 0 0 0-3.563 2.3l-2.775 6.85a3.879 3.879 0 0 0 .236 3.651 3.778 3.778 0 0 0 3.271 1.659h10.418v-2.38h-10.418a1.447 1.447 0 0 1-1.268-.57 1.488 1.488 0 0 1-.057-1.4z"
          transform="translate(-173.992 -284.082) translate(-54.92 -.008)"
        />
        <path
          fill={color}
          d="M335.47 297.763l-3.189-4.13a3.75 3.75 0 0 0 2.607-4.975L334 286.58l-.018-.045a3.854 3.854 0 0 0-3.84-2.383l-9.9-.071v14.479h2.383v-4.834h6.716l4.251 5.505zm-12.843-11.285l7.51.054c1.086 0 1.439.44 1.65.922l.873 2.048a1.344 1.344 0 0 1-.154 1.27 1.253 1.253 0 0 1-.986.573h-8.893z"
          transform="translate(-173.992 -284.082) translate(-72.478)"
        />
        <path
          fill={color}
          d="M230.791 298.583h2.573l-4.847-11.894a3.833 3.833 0 0 0-3.627-2.557h-1.927a3.832 3.832 0 0 0-3.626 2.557l-4.846 11.894h2.572l1.962-4.815h9.8zm-10.8-7.194l1.562-3.833.025-.068a1.451 1.451 0 0 1 1.382-.977h1.927a1.452 1.452 0 0 1 1.382.977l1.589 3.9z"
          transform="translate(-173.992 -284.082) translate(-20.072 -.025)"
        />
        <path
          fill="#2b94e8"
          d="M176.356 295.252v.005l1.059 2.676a1.1 1.1 0 0 0 1.069.672h11.232a1.166 1.166 0 0 0 1.142-.607l2.8-7.013a.931.931 0 0 0-.093-.888.869.869 0 0 0-.773-.379h-15.597v-.005l.9-2.175h15.466v-.005l-.96-2.337a1.623 1.623 0 0 0-1.5-1.006h-14.164a1.623 1.623 0 0 0-1.5 1l-1.32 3.19a1.634 1.634 0 0 0-.008 1.228l1.373 3.454H189.2l-.858 2.181z"
          transform="translate(-173.992 -284.082) translate(0 -.053)"
        />
      </g>
    </chakra.svg>
  );
};
