import { JSONContent } from '@tiptap/react';
import {
  FirestoreDataConverter,
  WithFieldValue,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';
import { dateFormat } from 'lib/utils';

export type Doc = {
  id: string;
  title: string;
  body: string;
  createdAt: string;
};

export type DocFormValues = {
  title: string;
  body: JSONContent;
};

export const DocConverter: FirestoreDataConverter<Doc> = {
  toFirestore(article: WithFieldValue<Doc>): DocumentData {
    return {};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Doc {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      title: data.title,
      body: data.body,
      createdAt: dateFormat(data.createdAt?.toDate()) || '-',
    };
  },
};
