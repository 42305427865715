import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';
import { dateFormat } from 'lib/utils';

type Answer = { answer: string; correct: boolean };

export type Quiz = {
  id: string;
  status: string;
  question: string;
  answers: Answer[];
  comment?: String;
  dueDate: string;
  dueTime: string;
  createdAt: string;
  updatedAt?: string;
};

export type QuizFormValues = {
  status: string;
  question: string;
  answers: number;
  comment?: String;
  dueDate: string;
  dueTime: string;
};

export const QuizConverter: FirestoreDataConverter<Quiz> = {
  toFirestore(article: WithFieldValue<Quiz>): DocumentData {
    return {};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Quiz {
    const data = snapshot.data(options);

    let dueDate = '',
      dueTime = '';
    if (data.dateTime) {
      const dateTime = data.dateTime.toDate();
      dueDate = dateFormat(dateTime, 'YYYY-MM-DD') || '';
      dueTime = dateFormat(dateTime, 'HH') || '';
    }

    return {
      id: snapshot.id,
      status: data.status,
      question: data.question,
      answers: data.answers,
      comment: data.comment,
      dueDate,
      dueTime,
      createdAt: dateFormat(data.createdAt?.toDate()) || '-',
      // updatedAt: dateFormat(data.updatedAt?.toDate()) || '-',
    };
  },
};
