import { useEffect } from 'react';
import dayjs from 'dayjs';
import { SubmitHandler, useForm } from 'react-hook-form';
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import {
  Button,
  FormControl,
  Stack,
  Input,
  Select,
  useColorModeValue,
} from '@chakra-ui/react';

import { db } from 'lib/firebase';
import { ArticleDetail, ReserveFormValues } from '../domain/Article';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';

export const Reserve = ({ article }: { article: ArticleDetail }) => {
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const selectBg = useColorModeValue('white', 'gray.800');
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting },
  } = useForm<ReserveFormValues>({
    mode: 'onChange',
  });

  useEffect(() => {
    const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD 00:00:00');
    const date = article.scheduledAt
      ? dayjs(article.scheduledAt)
      : dayjs(tomorrow);
    reset({
      dueDate: date.format('YYYY-MM-DD'),
      dueTime: date.format('HH'),
      dueMinute: date.format('mm'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  const onSubmit: SubmitHandler<ReserveFormValues> = async (data) => {
    try {
      const now = dayjs();
      const scheduled = dayjs(
        `${data.dueDate} ${data.dueTime}:${data.dueMinute}:00`
      );
      if (scheduled < now) {
        throw Error('예약일시는 현재 이후여야 합니다');
      }

      await updateDoc(doc(db, 'articles', article.id), {
        status: 'scheduled',
        scheduledAt: Timestamp.fromDate(scheduled.toDate()),
      });
      toastSuccess({ description: '게재 예약했습니다' });
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    }
  };

  return article.status === 'published' ? (
    <></>
  ) : (
    <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="2" direction={{ base: 'column', md: 'row' }}>
        <FormControl flex={2}>
          <Input type="date" fontSize="sm" {...register('dueDate')} />
        </FormControl>
        <FormControl flex={1}>
          <Select
            fontSize="sm"
            placeholder="시"
            bg={selectBg}
            {...register('dueTime')}
          >
            {[...Array(7)].map((x, i) => {
              const h = i;
              const hour: string = h < 10 ? `0${h}` : `${h}`;
              return (
                <option key={`h${i}`} value={hour}>
                  {hour}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <FormControl flex={1}>
          <Select
            fontSize="sm"
            placeholder="분"
            bg={selectBg}
            {...register('dueMinute')}
          >
            <option value="00">00</option>
            <option value="30">30</option>
          </Select>
        </FormControl>
        <Button
          type="submit"
          flex={1}
          fontSize="sm"
          disabled={!isValid}
          isLoading={isSubmitting}
        >
          게재 예약
        </Button>
      </Stack>
    </form>
  );
};
