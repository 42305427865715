import { useMemo, useCallback, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Spinner,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';

import { db } from 'lib/firebase';
import { editAtom } from '.';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';
import { QuizConverter, QuizFormValues } from '../domain/Quiz';
import { SubmitHandler, useForm } from 'react-hook-form';
import { DragContainer, Item } from 'components/dnd/DragContainer';
import dayjs from 'dayjs';

const collectionName = 'quizzes';
const defaultItem: Item = {
  id: 'temp',
  text: '',
  correct: false,
};

export const QuizEdit = () => {
  const [cards, setCards] = useState<Item[]>([]);
  const [item, setItem] = useState<Item>(defaultItem);
  const [edit, setEdit] = useAtom(editAtom);

  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const selectBg = useColorModeValue('white', 'gray.800');
  const footerBg = useColorModeValue('gray.50', 'gray.600');

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isValid, isDirty, isSubmitting, errors },
  } = useForm<QuizFormValues>({
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('answers', cards.length, { shouldTouch: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards]);

  const isOpen = useMemo(() => !!edit, [edit]);
  const onClose = useCallback(() => {
    setCards([]);
    reset({}, { keepValues: false });
    setEdit(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [value, loading] = useDocumentData(
    edit && edit !== 'new'
      ? doc(db, collectionName, edit).withConverter(QuizConverter)
      : null
  );

  useEffect(() => {
    if (value) {
      reset({
        question: value.question,
        comment: value.comment,
        dueDate: value.dueDate,
        dueTime: value.dueTime,
      });
      setCards(
        value.answers.map((a, i) => ({
          id: `c${i}`,
          text: a.answer,
          correct: a.correct || false,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const addAnswer = () => {
    const newItem = { ...item, id: `c${cards.length}` };
    setCards((cards) => [...cards, newItem]);
    setItem(defaultItem);
  };

  const onSubmit: SubmitHandler<QuizFormValues> = async (data) => {
    try {
      if (cards.length < 2) {
        throw Error('답변 항목을 최소 2개 이상 등록해 주세요');
      }

      const correctCount = cards.filter((x) => x.correct).length;
      if (correctCount > 1) {
        throw Error('정답이 2개 이상 포함되어 있습니다');
      } else if (correctCount < 1) {
        throw Error('정답이 포함된 답변이 없습니다');
      }

      const saveData = {
        question: data.question,
        dateTime: Timestamp.fromDate(
          dayjs(`${data.dueDate}T${data.dueTime}:00:00`).toDate()
        ),
        answers: cards.map((card) => ({
          answer: card.text,
          correct: card.correct,
        })),
        ...(data.comment && { comment: data.comment }),
        status: 'pending',
      };

      if (edit === 'new') {
        await addDoc(collection(db, collectionName), {
          ...saveData,
          createdAt: serverTimestamp(),
        });
      } else {
        await updateDoc(doc(db, collectionName, edit!), {
          ...saveData,
          updatedAt: serverTimestamp(),
        });
      }
      // await updateDoc(doc(db, collectionName, edit!), {
      //   articles: cards.map((card) => card.id),
      // });
      toastSuccess({ description: '퀴즈를 수정했습니다' });
      onClose();
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>퀴즈 {edit === 'new' ? '작성' : '수정'}</DrawerHeader>
        <DrawerBody py="5">
          {loading && !value ? (
            <Spinner />
          ) : (
            <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={4}>
                <FormControl isInvalid={!!errors.question}>
                  <FormLabel>문제</FormLabel>
                  <Textarea
                    fontSize="sm"
                    {...register('question', {
                      required: '필수항목입니다',
                    })}
                  />
                  <FormErrorMessage fontSize="xs">
                    {errors.question && errors.question.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.dueDate}>
                  <FormLabel>출제일시</FormLabel>
                  <HStack spacing="4">
                    <FormControl flex={2}>
                      <Input
                        type="date"
                        fontSize="sm"
                        {...register('dueDate', {
                          required: '필수항목입니다',
                        })}
                      />
                      <FormErrorMessage fontSize="xs">
                        {errors.dueDate && errors.dueDate.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl flex={1}>
                      <Select
                        fontSize="sm"
                        placeholder="시간"
                        bg={selectBg}
                        {...register('dueTime')}
                      >
                        <option value="11">11</option>
                        <option value="15">15</option>
                      </Select>
                    </FormControl>
                  </HStack>
                </FormControl>
                {cards.length && (
                  <Stack>
                    <Text fontSize="sm">답변 항목</Text>
                    <DndProvider backend={HTML5Backend}>
                      <DragContainer cards={cards} setCards={setCards} />
                    </DndProvider>
                  </Stack>
                )}
                <HStack>
                  <Input
                    flex="2"
                    type="text"
                    value={item.text}
                    onChange={(e) => setItem({ ...item, text: e.target.value })}
                  />
                  <HStack spacing={1}>
                    <Text fontSize="sm">정답</Text>
                    <Checkbox
                      isChecked={item.correct}
                      onChange={(e) =>
                        setItem({ ...item, correct: e.target.checked })
                      }
                    />
                  </HStack>
                  <Button type="button" fontSize="sm" onClick={addAnswer}>
                    답변등록
                  </Button>
                </HStack>
                <FormControl isInvalid={!!errors.question}>
                  <FormLabel>오답시 코멘트</FormLabel>
                  <Textarea fontSize="sm" {...register('comment')} />
                  <FormErrorMessage fontSize="xs">
                    {errors.question && errors.question.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </form>
          )}
        </DrawerBody>
        {(edit === 'new' || (value && value.status !== 'completed')) && (
          <DrawerFooter bg={footerBg}>
            <Button
              type="submit"
              form="my-form"
              variant="primary"
              isLoading={isSubmitting}
              disabled={!isDirty || !isValid}
            >
              저장
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};
