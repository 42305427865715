import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { useAtom } from 'jotai';
import {
  collection,
  deleteField,
  doc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  Button,
  HStack,
  Input,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { db } from 'lib/firebase';
import { appUserAtom } from 'index';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';
import { Reserve } from './Reserve';
import { ArticleDetail } from '../domain/Article';

export const ControlPanel = ({ article }: { article?: ArticleDetail }) => {
  const [processing, setProcessing] = useState(false);
  const [date, setDate] = useState('');

  const [appUser] = useAtom(appUserAtom);
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const selectBg = useColorModeValue('white', 'gray.800');

  const changeStatus = useCallback(
    async (event: FormEvent<HTMLSelectElement>) => {
      if (!article) return;
      const status: string = event.currentTarget.value;
      try {
        await updateDoc(doc(db, 'articles', article.id), {
          status: status,
          ...(status === 'published'
            ? { publishedAt: serverTimestamp() }
            : {
                today: deleteField(),
                publishedAt: deleteField(),
              }),
        });
        toastSuccess({ description: '기사 상태를 변경했습니다' });
      } catch (error) {
        if (error instanceof Error) {
          toastError({ description: error.message });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [article]
  );

  const changeDate = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.currentTarget.value);
  }, []);

  const setTodayArticle = useCallback(
    async () => {
      if (!article || !date) return;
      setProcessing(true);
      try {
        const today = new Date(`${date}T00:00:00`);
        const q = query(
          collection(db, 'articles'),
          where('today', '==', today)
        );
        const snapshots = await getDocs(q);

        if (snapshots.size > 0) {
          throw Error('걑은 날로 선정된 기사가 이미 있습니다');
        }

        await updateDoc(doc(db, 'articles', article.id), { today });
        toastSuccess({ description: '오늘의 기사로 선정했습니다' });
      } catch (error) {
        if (error instanceof Error) {
          toastError({ description: error.message });
        }
      } finally {
        setProcessing(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date]
  );

  const cancelTodayArticle = useCallback(
    async () => {
      if (!article) return;
      setProcessing(true);
      try {
        await updateDoc(doc(db, 'articles', article.id), {
          today: deleteField(),
        });
        toastSuccess({ description: '오늘의 기사 취소했습니다' });
      } catch (error) {
        if (error instanceof Error) {
          toastError({ description: error.message });
        }
      } finally {
        setProcessing(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [article]
  );

  if (appUser?.role !== 'chief' || !article) {
    return <></>;
  }

  return (
    <Stack>
      <Select
        fontSize="sm"
        placeholder="기사 상태 변경"
        bg={selectBg}
        value={article.status}
        onChange={changeStatus}
      >
        <option value="completed">작성완료</option>
        <option value="released">출고</option>
        <option value="reserved" disabled>
          예약
        </option>
        <option value="published">게재</option>
      </Select>
      <Reserve article={article} />
      {article.status === 'published' &&
        (article.today ? (
          <HStack spacing={3}>
            <HStack flex={1}>
              <Text>설정일</Text>
              <Text fontWeight="semibold">{article.today}</Text>
            </HStack>
            <Button
              flex={1}
              isLoading={processing}
              onClick={cancelTodayArticle}
            >
              오늘의 기사 취소
            </Button>
          </HStack>
        ) : (
          <HStack>
            <Input flex={1} type="date" fontSize="sm" onChange={changeDate} />
            <Button
              flex={1}
              fontSize="sm"
              disabled={!date}
              isLoading={processing}
              onClick={setTodayArticle}
            >
              오늘의 기사로 선정
            </Button>
          </HStack>
        ))}
    </Stack>
  );
};
