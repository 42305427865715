import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { atom, Provider } from 'jotai';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';

import { App } from './App';
import theme from './theme';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import './index.css';
import '@fontsource/quicksand/variable.css';

type AppUser = {
  uid: string;
  email: string;
  displayName: string | null;
  photoURL: string | null;
  role: string;
};

export const appUserAtom = atom<AppUser | null | undefined>(null);
// export const userClaimsAtom = atom<ParsedToken | null>(null);

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <Provider>
        <App />
      </Provider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
