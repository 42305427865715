import { useNavigate } from 'react-router-dom';
import { As, Button, ButtonProps, HStack, Icon, Text } from '@chakra-ui/react';

interface NavButtonProps extends ButtonProps {
  icon: As;
  label: string;
  href?: string;
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, href, ...buttonProps } = props;
  const navigate = useNavigate();

  const goTo = () => {
    if (href) {
      navigate(href);
    }
  };

  return (
    <Button
      variant="ghost"
      justifyContent="start"
      onClick={goTo}
      {...buttonProps}
    >
      <HStack spacing="3">
        <Icon as={icon} boxSize="6" color="subtle" />
        <Text>{label}</Text>
      </HStack>
    </Button>
  );
};
