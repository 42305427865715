import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';
import { dateFormat } from 'lib/utils';

export type Word = {
  id: string;
  body: string;
  today?: string;
  editorName: string;
  createdAt: string;
  updatedAt: string;
};

export type WordFormValues = {
  body: string;
};

export const WordConverter: FirestoreDataConverter<Word> = {
  toFirestore(word: WithFieldValue<Word>): DocumentData {
    return {
      body: word.body,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Word {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      body: data.body,
      editorName: data.editorName,
      today: dateFormat(data.today?.toDate(), 'YYYY-MM-DD') || undefined,
      createdAt: dateFormat(data.createdAt?.toDate()) || '-',
      updatedAt: dateFormat(data.updatedAt?.toDate()) || '-',
    };
  },
};
