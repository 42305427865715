import { ThemeConfig, extendTheme } from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
};

const theme = extendTheme(
  {
    config,
    colors: { ...proTheme.colors, brand: proTheme.colors.blue },
    fonts: {
      heading: 'QuicksandVariable, sans-serif',
      body: 'QuicksandVariable, sans-serif',
    },
  },
  proTheme
);

export default theme;
