import {
  useRef,
  useMemo,
  useCallback,
  useState,
  FormEvent,
  useEffect,
} from 'react';
import { useAtom } from 'jotai';
import {
  Grid,
  GridItem,
  AspectRatio,
  Box,
  Flex,
  IconButton,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { FiEye, FiTrash2, FiUploadCloud } from 'react-icons/fi';

import { deletesAtom, imagesAtom, ImageType } from 'features/upload';
import resizeImage from 'lib/resizeImage';

type InputImagesProps = {
  // attached: boolean;
  onChange: (value: number) => void;
  multiple?: boolean;
};

export const InputImages = ({
  onChange,
  multiple = false,
}: InputImagesProps) => {
  const [show, setShow] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const isOpen = useMemo(() => !!show, [show]);
  const onClose = useCallback(() => setShow(null), [setShow]);

  const [images, setImages] = useAtom(imagesAtom);
  const [deletes, setDeletes] = useAtom(deletesAtom);

  useEffect(() => {
    onChange(images.length);
  }, [images, onChange]);

  const fileHandler = async (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (!files) return;
    const file = files[0];
    if (!file) return;

    try {
      const imageData = await resizeImage(file, 'string');
      if (imageData) {
        setImages(
          images.concat({
            mode: 'add',
            url: imageData as string,
          })
        );
      }
      target.value = '';
    } catch (error) {}
  };

  const willDeleteImage = (index: number, image: ImageType) => {
    if (image.mode === 'url') {
      setDeletes(deletes.concat(image.url));
    }
    setImages(images.filter((_, i) => i !== index));
  };

  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap={3}>
        {images.map((image, i) => (
          <GridItem key={i}>
            <AspectRatio ratio={1}>
              <Box borderRadius="6" overflow="hidden">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  left="0"
                  top="0"
                  right="0"
                  bottom="0"
                  bg="blackAlpha.700"
                  opacity="0"
                  transitionDuration="300ms"
                  _hover={{ opacity: 1 }}
                >
                  <IconButton
                    colorScheme="ghost"
                    aria-label="Show"
                    icon={<FiEye />}
                    onClick={() => setShow(image.url)}
                  />
                  <IconButton
                    colorScheme="ghost"
                    aria-label="Delete"
                    icon={<FiTrash2 color="red" />}
                    onClick={() => willDeleteImage(i, image)}
                  />
                </Flex>
                {image.url && (
                  <Image
                    src={image.url}
                    alt={`image_${i}`}
                    w="full"
                    h="full"
                    objectFit="cover"
                  />
                )}
              </Box>
            </AspectRatio>
          </GridItem>
        ))}
        {(multiple || !images.length) && (
          <GridItem>
            <AspectRatio ratio={1}>
              <Button
                leftIcon={<FiUploadCloud />}
                onClick={() => inputRef.current!.click()}
              ></Button>
            </AspectRatio>
          </GridItem>
        )}
      </Grid>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={fileHandler}
      />
      <Modal
        blockScrollOnMount={false}
        size="3xl"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        {show && (
          <ModalContent>
            <ModalCloseButton color="white" />
            <ModalBody padding="0" overflow="hidden">
              <Image src={show} alt="Original" width="full" />
            </ModalBody>
          </ModalContent>
        )}
      </Modal>
    </>
  );
};
