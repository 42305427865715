import { Badge } from '@chakra-ui/react';
import {
  STATUS,
  AMPM_STATUS,
  PUSH_STATUS,
  ENCASH_STATUS,
} from 'constants/index';

export const StatusBadge = ({ state }: { state: string }) => {
  const status = STATUS[state];

  if (!status) {
    return <Badge colorScheme="red">오류</Badge>;
  }

  return <Badge colorScheme={status.color}>{status.text}</Badge>;
};

export const SummaryStatusBadge = ({ state }: { state: string }) => {
  const status = AMPM_STATUS[state];

  if (!status) {
    return <Badge colorScheme="red">오류</Badge>;
  }

  return <Badge colorScheme={status.color}>{status.text}</Badge>;
};

export const PushStatusBadge = ({ state }: { state: string }) => {
  const status = PUSH_STATUS[state];

  if (!status) {
    return <Badge colorScheme="red">오류</Badge>;
  }

  return <Badge colorScheme={status.color}>{status.text}</Badge>;
};

export const EncashStatusBadge = ({ state }: { state: string }) => {
  const status = ENCASH_STATUS[state];

  if (!status) {
    return <Badge colorScheme="red">오류</Badge>;
  }

  return <Badge colorScheme={status.color}>{status.text}</Badge>;
};
