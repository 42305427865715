import React, { forwardRef } from 'react';
import _ from 'lodash';
import ReactDatePicker, {
  // ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import ko from 'date-fns/locale/ko';
// import { useColorMode } from "@chakra-ui/react";
import {
  InputGroup,
  Input,
  InputRightElement,
  HStack,
  Select,
  IconButton,
  DarkMode,
} from '@chakra-ui/react';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';

import 'react-datepicker/dist/react-datepicker.css';
import './chakra-react-datepicker.css';
import dayjs from 'dayjs';

registerLocale('ko', ko);

const years = _.range(2021, dayjs().get('year') + 1, 1);

const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
  <Input
    autoComplete="off"
    background="white"
    size="sm"
    value={value}
    ref={ref}
    onClick={onClick}
    onChange={onChange}
  />
);
customDateInput.displayName = 'DateInput';

const CustomInput = forwardRef(customDateInput);

const icon = <CalendarIcon fontSize="sm" />;

interface Props {
  isClearable?: boolean;
  onChange: (date: Date) => any;
  selectedDate: Date | undefined;
  showPopperArrow?: boolean;
  selectsStart?: boolean | undefined;
  selectsEnd?: boolean | undefined;
  startDate?: Date | null | undefined;
  endDate?: Date | null | undefined;
  minDate?: Date | null | undefined;
}

// const ReactDatePickerComponent = ({
//   selectedDate,
//   onChange,
//   isClearable = false,
//   showPopperArrow = false,
//   ...props
// }: Props & HTMLAttributes<HTMLElement>) => {
//   // const isLight = useColorMode().colorMode === "light";
//   return (
//     <>
//       <InputGroup>
//         <ReactDatePicker
//           selected={selectedDate}
//           onChange={onChange}
//           isClearable={isClearable}
//           showPopperArrow={showPopperArrow}
//           className="react-datapicker__input-text"
//           dateFormat="MM/dd/yy"
//           customInput={<CustomInput />}
//         />
//         <InputRightElement color="gray.500" children={icon} />
//       </InputGroup>
//     </>
//   );
// };

const DatePicker = ({ selectedDate, onChange, ...props }: Props) => {
  return (
    <>
      <InputGroup size="sm" className="light-theme">
        <ReactDatePicker
          selected={selectedDate}
          onChange={onChange}
          className="react-datapicker__input-text"
          customInput={<CustomInput />}
          dateFormat="yyyy-MM-dd"
          locale={ko}
          {...props}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <HStack spacing={1}>
              <DarkMode>
                <IconButton
                  icon={<ChevronLeftIcon />}
                  size="sm"
                  aria-label="이전"
                  onClick={decreaseMonth}
                  isDisabled={prevMonthButtonDisabled}
                />
              </DarkMode>
              <DarkMode>
                <Select
                  flex={3}
                  size="sm"
                  value={dayjs(date).get('year')}
                  onChange={({ target: { value } }) =>
                    changeYear(Number(value))
                  }
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </DarkMode>
              <Select
                flex={2}
                size="sm"
                value={dayjs(date).get('month')}
                onChange={({ target: { value } }) => changeMonth(Number(value))}
              >
                {_.range(0, 12).map((option) => (
                  <option key={option} value={option}>
                    {option + 1}
                  </option>
                ))}
              </Select>
              <IconButton
                icon={<ChevronRightIcon />}
                size="sm"
                aria-label="다음"
                onClick={increaseMonth}
                isDisabled={prevMonthButtonDisabled}
              />
            </HStack>
          )}
        />
        <InputRightElement color="gray.500" children={icon} />
      </InputGroup>
    </>
  );
};

// set className to "light-theme-original"
// {
/* <div className={isLight ? "light-theme" : "dark-theme"}>
<ReactDatePicker
  selected={selectedDate}
  onChange={onChange}
  isClearable={isClearable}
  showPopperArrow={showPopperArrow}
  className="react-datapicker__input-text"
  dateFormat="MM/dd/yyyy"
  customInput={<CustomInput />}
/>
</div> */
// }

export default DatePicker;
