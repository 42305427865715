import { FormEvent, useCallback, useMemo } from 'react';
import { useAtom } from 'jotai';
import {
  addDoc,
  collection,
  doc,
  increment,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import {
  AspectRatio,
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Select,
  StackDivider,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { iShowAtom, showAtom } from '.';
import { db } from 'lib/firebase';
import { numberFormat } from 'lib/utils';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';
import { EncashmentConverter } from '../domain/Encashment';
import { ENCASH_STATUS } from 'constants/index';

type Coin = {
  id: string;
  amount: number;
  deduced: number;
  date: number;
};

const deducePoints = async (uid: string, amount: number) => {
  let total = 0;
  const coins: Coin[] = [];
  const q = query(
    collection(db, `users/${uid}/coins`),
    where('amount', '>', 0)
  );
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
    const coin = doc.data();
    total += coin.amount;
    coins.push({
      id: doc.id,
      amount: coin.amount,
      deduced: coin.deduced || 0,
      date: coin.createdAt.toMillis(),
    });
  });
  if (amount > total) {
    throw new Error('전환할 수 있는 세코가 부족합니다');
  }

  const batch = writeBatch(db);
  let remain = amount;
  coins.sort((a, b) => a.date - b.date);
  coins.forEach((coin) => {
    if (remain > 0) {
      const deduced = coin.amount >= remain ? remain : coin.amount;
      remain -= deduced;
      const ref = doc(db, `users/${uid}/coins`, coin.id);
      batch.update(ref, {
        amount: coin.amount - deduced,
        deduced: coin.deduced + deduced,
      });
    }
  });
  await batch.commit();
  // 차감내역기록
  await addDoc(collection(db, `users/${uid}/deductions`), {
    amount,
    description: '현금전환',
    createdAt: serverTimestamp(),
  });
  // 유저 코인 업데이트
  await updateDoc(doc(db, `users`, uid), {
    coins: increment(-amount),
  });
};

export const EncashmentShow = () => {
  const [show, setShow] = useAtom(showAtom);
  const [, setIShow] = useAtom(iShowAtom);
  const isOpen = useMemo(() => !!show, [show]);
  const onClose = useCallback(() => setShow(null), [setShow]);

  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const selectBg = useColorModeValue('white', 'gray.800');

  const [encashment] = useDocumentData(
    show
      ? doc(db, `users/${show.uid}/encashments`, show.id).withConverter(
          EncashmentConverter
        )
      : null
  );

  const changeStatus = useCallback(
    async (event: FormEvent<HTMLSelectElement>) => {
      if (!encashment) return;
      const status: string = event.currentTarget.value;
      if (status === '') return;
      try {
        if (status === 'completed') {
          await deducePoints(encashment.uid, encashment.amount);
        }
        await updateDoc(
          doc(db, `users/${encashment.uid}/encashments`, encashment.id),
          {
            status,
            updatedAt: serverTimestamp(),
          }
        );
        toastSuccess({ description: '상태를 변경했습니다' });
      } catch (error) {
        if (error instanceof Error) {
          toastError({ description: error.message });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [encashment]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack>
            <Text>현금전환신청</Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {encashment && (
            <Stack spacing="5">
              <Stack divider={<StackDivider />} spacing="3">
                <Stack spacing="1">
                  <Text color="muted" fontSize="sm">
                    성명
                  </Text>
                  <Text fontWeight="semibold">{encashment.name}</Text>
                </Stack>
                <Stack spacing="1">
                  <Text color="muted" fontSize="sm">
                    주민등록번호
                  </Text>
                  <Text fontWeight="semibold">{encashment.security}</Text>
                </Stack>
                <AspectRatio ratio={16 / 9}>
                  <Box
                    borderRadius="6"
                    overflow="hidden"
                    cursor="pointer"
                    onClick={() => setIShow(encashment.imageURL)}
                  >
                    <Image
                      src={encashment.imageURL}
                      alt="주민등록증"
                      w="full"
                      h="full"
                      objectFit="cover"
                    />
                  </Box>
                </AspectRatio>
                <Stack spacing="1">
                  <Text color="muted" fontSize="sm">
                    은행
                  </Text>
                  <Text fontWeight="semibold">{encashment.bank}</Text>
                </Stack>
                <Stack spacing="1">
                  <Text color="muted" fontSize="sm">
                    계좌번호
                  </Text>
                  <Text fontWeight="semibold">{encashment.account}</Text>
                </Stack>
                <Stack spacing="1">
                  <Text color="muted" fontSize="sm">
                    신청금액
                  </Text>
                  <Text fontSize="lg" fontWeight="semibold">
                    {numberFormat(encashment.amount)}원
                  </Text>
                </Stack>
              </Stack>
              <Select
                fontSize="sm"
                placeholder="상태 변경"
                bg={selectBg}
                value={encashment.status}
                onChange={changeStatus}
              >
                {Object.keys(ENCASH_STATUS).map((key) => (
                  <option
                    key={key}
                    value={key}
                    disabled={encashment.status === 'completed'}
                  >
                    {ENCASH_STATUS[key].text}
                  </option>
                ))}
              </Select>
            </Stack>
          )}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
