import { useMemo, useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';

import { db } from 'lib/firebase';
import { editAtom } from '.';
import { DocConverter, DocFormValues } from '../domain/Doc';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';
import { HTMLtoJSON, JSONtoHTML } from 'lib/parser';
import { TiptapEditor } from 'components/tiptap/Editor';

const collectionName = 'docs';

export const DocEdit = () => {
  const [edit, setEdit] = useAtom(editAtom);

  const toastSuccess = useToastSuccess();
  const toastError = useToastError();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, isSubmitting, errors },
  } = useForm<DocFormValues>({
    mode: 'onChange',
  });

  const isOpen = useMemo(() => !!edit, [edit]);
  const onClose = useCallback(() => {
    reset({}, { keepValues: false });
    setEdit(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [value, loading] = useDocumentDataOnce(
    edit && edit !== 'new'
      ? doc(db, collectionName, edit).withConverter(DocConverter)
      : null
  );

  useEffect(() => {
    if (value) reset({ ...value, body: HTMLtoJSON(value.body) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onSubmit: SubmitHandler<DocFormValues> = async (data) => {
    try {
      if (edit === 'new') {
        await addDoc(collection(db, collectionName), {
          title: data.title,
          body: JSONtoHTML(data.body),
          createdAt: serverTimestamp(),
        });
      } else {
        await setDoc(
          doc(db, collectionName, edit!),
          {
            title: data.title,
            body: JSONtoHTML(data.body),
            updatedAt: serverTimestamp(),
          },
          { merge: true }
        );
      }
      onClose();
      toastSuccess({ description: '저장했습니다.' });
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>문서 {edit === 'new' ? '등록' : '수정'}</DrawerHeader>
        <DrawerBody py="5">
          {loading && !value ? (
            <Spinner />
          ) : (
            <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing="5">
                <FormControl isInvalid={!!errors.title}>
                  <FormLabel>제목</FormLabel>
                  <Input
                    type="text"
                    fontSize="sm"
                    {...register('title', {
                      required: '필수항목입니다',
                    })}
                  />
                  <FormErrorMessage fontSize="xs">
                    {errors.title && errors.title.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel>내용</FormLabel>
                  <Controller
                    name="body"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TiptapEditor
                        height={500}
                        value={value}
                        collection="docs"
                        onChange={onChange}
                      />
                    )}
                  />
                </FormControl>
              </Stack>
            </form>
          )}
        </DrawerBody>
        <DrawerFooter bg={useColorModeValue('gray.50', 'gray.600')}>
          <Button
            type="submit"
            form="my-form"
            variant="primary"
            isLoading={isSubmitting}
            disabled={!isDirty || !isValid}
          >
            저장
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
