import { useAtom } from 'jotai';
import { Avatar, Box, HStack, Text } from '@chakra-ui/react';
import { appUserAtom } from 'index';

export const UserProfile = () => {
  const [appUser] = useAtom(appUserAtom);

  const image = appUser?.photoURL || undefined;
  const name = appUser?.displayName || '관리자';
  const email = appUser?.email;

  return (
    <HStack spacing="3" ps="2">
      <Avatar name={name} src={image} boxSize="10" />
      <Box>
        <Text fontWeight="medium" fontSize="sm">
          {name}
        </Text>
        <Text color="muted" fontSize="sm">
          {email}
        </Text>
      </Box>
    </HStack>
  );
};
