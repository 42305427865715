import { Box, Image, Stack, Text } from '@chakra-ui/react';
import { dateFormat } from 'lib/utils';

import { Preview } from '../../domain/Article';

type Props = {
  preview?: Preview;
};

export const PreviewBody = ({ preview }: Props) => {
  if (!preview) return <></>;

  return (
    <Stack>
      <Stack className="ProseMirror">
        <Stack spacing={1}>
          {preview.publishedAt && (
            <Box fontSize="sm" color="muted">
              게재일: {dateFormat(preview.publishedAt.toDate())}
            </Box>
          )}
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            {preview.title}
          </Text>
        </Stack>
        {preview.image && (
          <Stack>
            <Image src={preview.image} />
            <Text fontSize="xs" color="muted">
              {preview.caption} {preview.source}
            </Text>
          </Stack>
        )}
        <Stack spacing={0}>
          {preview.excerpt && (
            <Box dangerouslySetInnerHTML={{ __html: preview.excerpt }} />
          )}
          {preview.body && (
            <Box dangerouslySetInnerHTML={{ __html: preview.body }} />
          )}
        </Stack>
      </Stack>
      <Stack spacing={4}>
        {preview.relatedExt.length > 0 && (
          <Stack spacing={1}>
            <Text fontWeight="bold">관련기사</Text>
            <Stack spacing={2}>
              {preview.relatedExt.map((link, i) => (
                <Text key={`e${i}`} fontSize="sm" color="blue.400">
                  {link.title}
                </Text>
              ))}
            </Stack>
          </Stack>
        )}
        {preview.relatedInt.length > 0 && (
          <Stack spacing={1}>
            <Text fontWeight="bold">SAVER 관련기사</Text>

            <Stack spacing={2} color="blue.500">
              {preview.relatedInt.map((link, i) => (
                <Text key={`i${i}`} fontSize="sm">
                  {link.title}
                </Text>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
