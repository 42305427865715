import { useMemo, useCallback } from 'react';
import { useAtom } from 'jotai';
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

import { iShowAtom } from '.';

export const ImageShow = () => {
  const [iShow, setIShow] = useAtom(iShowAtom);
  const isOpen = useMemo(() => !!iShow, [iShow]);
  const onClose = useCallback(() => setIShow(null), [setIShow]);

  return (
    <Modal
      blockScrollOnMount={false}
      size="3xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      {iShow && (
        <ModalContent>
          <ModalCloseButton color="white" />
          <ModalBody padding="0" overflow="hidden">
            <Image src={iShow} alt="Original" width="full" />
          </ModalBody>
        </ModalContent>
      )}
    </Modal>
  );
};
