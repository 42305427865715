// import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { Navigate, useLocation } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { signInWithEmailAndPassword } from 'firebase/auth';
import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';

import { appUserAtom } from 'index';
import { auth } from 'lib/firebase';
import { useToastError } from 'components/start-ui/Toast';
import { Logo } from 'components/layouts/Logo';

type LocationProps = {
  state: {
    from: Location;
  };
};

export type FormValues = {
  email: string;
  password: string;
};

const LoginForm = () => {
  const toastError = useToastError();

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting, errors },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    }
  };

  return (
    <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="8">
        <Stack spacing="6" alignItems="center">
          <Logo />
        </Stack>
        <Stack spacing="6">
          <Stack spacing="5">
            <FormControl isInvalid={!!errors.email}>
              <FormLabel htmlFor="email">이메일</FormLabel>
              <Input
                id="email"
                type="email"
                {...register('email', {
                  required: '필수항목입니다',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: '이메일 형식이 아닙니다',
                  },
                })}
              />
              <FormErrorMessage fontSize="xs">
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password}>
              <FormLabel htmlFor="password">비밀번호</FormLabel>
              <Input
                id="password"
                type="password"
                {...register('password', {
                  required: '필수항목입니다',
                  minLength: {
                    value: 6,
                    message: '6자 이상 입력해 주세요',
                  },
                })}
              />
              <FormErrorMessage fontSize="xs">
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
          {/* <HStack justify="space-between">
            <Checkbox defaultIsChecked>Remember me</Checkbox>
            <Button variant="link" colorScheme="blue" size="sm">
              Forgot password
            </Button>
          </HStack> */}
        </Stack>
        <Stack spacing="4">
          <Button
            type="submit"
            variant="primary"
            isLoading={isSubmitting}
            disabled={!isDirty && !isValid}
          >
            로그인
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export const SignIn = () => {
  const location = useLocation() as unknown as LocationProps;
  const from = location.state?.from?.pathname || '/';

  const [appUser] = useAtom(appUserAtom);

  if (appUser) {
    return <Navigate to={from} />;
  }

  return (
    <Container maxW="md" py={{ base: '12', md: '24' }}>
      <LoginForm />
    </Container>
  );
};
