import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { FileUploader } from 'react-drag-drop-files';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Divider,
  HStack,
  Text,
  Stack,
} from '@chakra-ui/react';

import { editAtom } from 'features/articles/presentation';
import { db, storage } from 'lib/firebase';
import resizeImage from 'lib/resizeImage';
import { FiUploadCloud } from 'react-icons/fi';

import { useToastError } from 'components/start-ui/Toast';

type Props = {
  type: string;
  collection?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  value: string;
  setValue: (value: string) => void;
};

export const EditorModal = ({
  type,
  collection,
  open,
  setOpen,
  value,
  setValue,
}: Props) => {
  const [processing, setProcessing] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const [articleId] = useAtom(editAtom);
  const toastError = useToastError();

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const isOpen = useMemo(() => open, [open]);
  const text = useMemo(() => {
    switch (type) {
      case 'link':
        return 'URL';
      case 'youtube':
        return '유튜브 URL';
      case 'image':
        return '이미지 URL';
      default:
        break;
    }
  }, [type]);

  const inputHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileHandler = async (file: File) => {
    setProcessing(true);
    try {
      const imageBlob = (await resizeImage(file, 'blob')) as Blob;
      const fileName = dayjs().format('YYYYMMDDHHmmss.SSS');
      const storageRef = collection
        ? ref(storage, `${collection}/${fileName}.jpg`)
        : ref(storage, `articles/${articleId}/${fileName}.jpg`);
      const snapshot = await uploadBytes(storageRef, imageBlob);
      const downloadURL = await getDownloadURL(snapshot.ref);
      if (!collection) {
        await updateDoc(doc(db, 'articles', articleId!), {
          images: arrayUnion(downloadURL),
        });
      }
      setValue(downloadURL);
      onClose();
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
      setValue('');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      blockScrollOnMount={false}
      isCentered={true}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogBody pt={5} pb={2}>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>{text}을 입력하세요</FormLabel>
                <Input
                  type="text"
                  fontSize="sm"
                  value={value}
                  onChange={inputHandler}
                />
              </FormControl>
              {type === 'image' && (
                <>
                  <HStack>
                    <Divider />
                    <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                      또는
                    </Text>
                    <Divider />
                  </HStack>
                  <FileUploader
                    multiple={false}
                    name="file"
                    types={['jpg', 'jpeg', 'gif', 'png', 'webp']}
                    handleChange={fileHandler}
                  >
                    <HStack
                      h={36}
                      bg="gray.100"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="lg"
                    >
                      <FiUploadCloud />
                      <Text color="muted" fontSize="sm">
                        클릭하거나 파일을 여기에 끌어 놓아 업로드하세요
                      </Text>
                    </HStack>
                  </FileUploader>
                </>
              )}
            </Stack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} isDisabled={processing} onClick={onClose}>
              취소
            </Button>
            <Button
              variant="primary"
              ml={3}
              isLoading={processing}
              onClick={onClose}
            >
              확인
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
