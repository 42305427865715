import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';
import { dateFormat } from 'lib/utils';

export type Encashment = {
  id: string;
  uid: string;
  status: string;
  amount: number;
  name: string;
  security: string;
  bank: string;
  account: string;
  imageURL: string;
  createdAt: string;
  updatedAt: string;
};

export const EncashmentConverter: FirestoreDataConverter<Encashment> = {
  toFirestore(article: WithFieldValue<Encashment>): DocumentData {
    return {};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Encashment {
    const data = snapshot.data(options);

    return {
      id: snapshot.id,
      uid: snapshot.ref.parent.parent?.id || '',
      status: data.status,
      amount: data.amount,
      name: data.name,
      security: data.security,
      bank: data.bank,
      account: data.account,
      imageURL: data.imageURL,
      createdAt: dateFormat(data.createdAt?.toDate()) || '-',
      updatedAt: dateFormat(data.updatedAt?.toDate()) || '-',
    };
  },
};
