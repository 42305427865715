import { useMemo, useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Spinner,
  Stack,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';

import { db } from 'lib/firebase';
import { editAtom } from '.';
import { MessageConverter, MessageFormValues } from '../domain/Message';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';
import { PUSH_CATEGORY } from 'constants/index';
import { PushStatusBadge } from 'components/StatusBadge';
import { JSONtoHTML } from 'lib/parser';
import { TiptapEditor } from 'components/tiptap/Editor';

const collectionName = 'messages';

export const MessageEdit = () => {
  const [edit, setEdit] = useAtom(editAtom);

  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const selectBg = useColorModeValue('white', 'gray.800');
  const footerBg = useColorModeValue('gray.50', 'gray.600');

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, isSubmitting, errors },
  } = useForm<MessageFormValues>({
    mode: 'onChange',
  });

  const isOpen = useMemo(() => !!edit, [edit]);
  const onClose = useCallback(() => {
    reset({}, { keepValues: false });
    setEdit(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [value, loading] = useDocumentData(
    edit && edit !== 'new'
      ? doc(db, collectionName, edit).withConverter(MessageConverter)
      : null
  );

  useEffect(() => {
    if (value) reset(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onSubmit: SubmitHandler<MessageFormValues> = async (data) => {
    try {
      let status = 'immediately';
      let scheduled: dayjs.Dayjs | null = null;
      if (data.dueDate) {
        const now = dayjs();
        scheduled = dayjs(
          `${data.dueDate} ${data.dueTime || '13'}:${data.dueMinute || '00'}`
        );
        if (scheduled < now) {
          throw Error('예약일시는 현재 이후여야 합니다');
        }
        status = 'scheduled';
      }

      if (edit === 'new') {
        await addDoc(collection(db, collectionName), {
          title: data.title,
          body: data.body,
          content: data.content,
          contentHTML: JSONtoHTML(data.content!),
          category: 'event',
          status,
          ...(scheduled && {
            scheduledAt: Timestamp.fromDate(scheduled.toDate()),
          }),
          createdAt: serverTimestamp(),
        });
      } else {
        await updateDoc(doc(db, 'messages', edit!), {
          title: data.title,
          body: data.body,
          ...(data.content && {
            content: data.content,
            contentHTML: JSONtoHTML(data.content),
          }),
          ...(data.articleId && { articleId: data.articleId }),
          status,
          ...(scheduled && {
            scheduledAt: Timestamp.fromDate(scheduled.toDate()),
          }),
          updatedAt: serverTimestamp(),
        });
      }
      onClose();
      toastSuccess({ description: '푸시알림을 저장했습니다' });
    } catch (error) {
      // console.log(error);
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>푸시알림 {edit === 'new' ? '작성' : '수정'}</DrawerHeader>
        <DrawerBody py="5">
          {loading && !value ? (
            <Spinner />
          ) : (
            <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={5}>
                <FormControl>
                  <FormLabel>카테고리</FormLabel>
                  {value ? (
                    <Input
                      type="text"
                      fontSize="sm"
                      isReadOnly={true}
                      value={PUSH_CATEGORY[value.category].text}
                    />
                  ) : (
                    <Input
                      type="text"
                      fontSize="sm"
                      isReadOnly={true}
                      value="이벤트"
                    />
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.title}>
                  <FormLabel>알림 제목</FormLabel>
                  <Input
                    type="text"
                    fontSize="sm"
                    {...register('title', {
                      required: '필수항목입니다',
                    })}
                  />
                  <FormErrorMessage fontSize="xs">
                    {errors.title && errors.title.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.body}>
                  <FormLabel>알림 텍스트</FormLabel>
                  <Textarea
                    rows={2}
                    resize="none"
                    fontSize="sm"
                    {...register('body', {
                      required: '필수항목입니다',
                    })}
                  />
                  <FormErrorMessage fontSize="xs">
                    {errors.body && errors.body.message}
                  </FormErrorMessage>
                </FormControl>
                {value?.articleId ? (
                  <FormControl isInvalid={!!errors.articleId}>
                    <FormLabel>연결 기사</FormLabel>
                    <Input
                      type="text"
                      fontSize="sm"
                      isReadOnly={true}
                      {...register('articleId')}
                    />
                    <FormErrorMessage fontSize="xs">
                      {errors.articleId && errors.articleId.message}
                    </FormErrorMessage>
                  </FormControl>
                ) : (
                  <FormControl>
                    <FormLabel>본문</FormLabel>
                    <Controller
                      name="content"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <TiptapEditor
                          height={300}
                          collection="messages"
                          value={value!}
                          onChange={onChange}
                        />
                      )}
                    />
                  </FormControl>
                )}
                <FormControl>
                  <FormLabel>예약일시</FormLabel>
                  <Stack spacing="4" direction={{ base: 'column', md: 'row' }}>
                    <FormControl flex={2}>
                      <Input
                        type="date"
                        fontSize="sm"
                        {...register('dueDate')}
                      />
                    </FormControl>
                    <FormControl flex={1}>
                      <Select
                        fontSize="sm"
                        placeholder="시"
                        bg={selectBg}
                        {...register('dueTime')}
                      >
                        {[...Array(12)].map((x, i) => {
                          const h = i + 7;
                          const hour: string = h < 10 ? `0${h}` : `${h}`;
                          return (
                            <option key={`h${i}`} value={hour}>
                              {hour}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl flex={1}>
                      <Select
                        fontSize="sm"
                        placeholder="분"
                        bg={selectBg}
                        {...register('dueMinute')}
                      >
                        <option value="00">00</option>
                        <option value="30">30</option>
                      </Select>
                    </FormControl>
                  </Stack>
                  <FormHelperText fontSize="xs">
                    예약일을 지정하지 않으면 저장 후 1분 이내에 발송됩니다.
                  </FormHelperText>
                </FormControl>
                {value && (
                  <FormControl>
                    <PushStatusBadge state={value.status} />
                  </FormControl>
                )}
              </Stack>
            </form>
          )}
        </DrawerBody>
        {(edit === 'new' || (value && value.status !== 'completed')) && (
          <DrawerFooter bg={footerBg}>
            <Button
              type="submit"
              form="my-form"
              variant="primary"
              isLoading={isSubmitting}
              disabled={!isDirty && !isValid}
            >
              저장
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};
