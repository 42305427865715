import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { ColumnDef } from '@tanstack/react-table';
import {
  doc,
  query,
  collection,
  where,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  Button,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import { FiBell, FiEdit, FiEye, FiTrash2 } from 'react-icons/fi';

import { db } from 'lib/firebase';
import { Article, ArticleConverter } from '../domain/Article';
import { GenericTable } from 'components/react-table/GenericTable';
import { appUserAtom } from 'index';
import { showAtom, editAtom, notiAtom, removeAtom, selectionAtom } from '.';
import { StatusBadge } from 'components/StatusBadge';
import IndeterminateCheckbox from 'components/react-table/IndeterminateCheckbox';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { resetStart, resetEnd } from 'lib/utils';
import { DownloadIcon, RepeatIcon } from '@chakra-ui/icons';
import DatePicker from 'components/datepicker/ReactDatePicker';

type Range = {
  startDate: Date;
  endDate: Date;
};

export const ArticlesList = () => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [range, setRange] = useState<Range | null>(null);

  const [appUser] = useAtom(appUserAtom);
  const [, setShow] = useAtom(showAtom);
  const [, setEdit] = useAtom(editAtom);
  const [, setRemove] = useAtom(removeAtom);
  const [, setSelection] = useAtom(selectionAtom);
  const [, setNoti] = useAtom(notiAtom);

  const initDate = useCallback(() => {
    const startDate = resetStart(dayjs().subtract(1, 'month'));
    const endDate = resetEnd(dayjs());
    setStartDate(startDate);
    setEndDate(endDate);
    setRange({
      startDate,
      endDate,
    });
  }, []);

  useEffect(() => {
    initDate();
  }, [initDate]);

  const isAdmin = useMemo(() => appUser?.role === 'chief', [appUser]);

  const dataQuery = useMemo(() => {
    if (!range) return null;

    const ref = collection(db, 'articles').withConverter(ArticleConverter);
    const queryConstraints = [
      where('createdAt', '>=', range.startDate),
      where('createdAt', '<=', range.endDate),
    ];

    if (!isAdmin && appUser) {
      queryConstraints.push(where('uid', '==', appUser?.uid));
    }

    return query(ref, ...queryConstraints);
  }, [range, isAdmin, appUser]);

  const [data, loading] = useCollectionData(dataQuery);
  // { snapshotListenOptions: { includeMetadataChanges: true } }

  useEffect(() => {
    setSelection({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeRange = useCallback(() => {
    if (!startDate || !endDate) return;
    setRange({
      startDate,
      endDate,
    });
  }, [startDate, endDate]);

  const createDraft = useCallback(async () => {
    const id = doc(collection(db, 'articles')).id;
    await setDoc(doc(db, 'articles', id), {
      title: '헤드라인 미정',
      uid: appUser?.uid,
      bodyHTML: '<p></p>',
      excerptHTML: '<p></p>',
      editorName: appUser?.displayName,
      editorEmail: appUser?.email,
      status: 'draft',
      createdAt: serverTimestamp(),
    });
    setEdit(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: ColumnDef<Article>[] = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <></>
          // <IndeterminateCheckbox
          //   {...{
          //     checked: table.getIsAllRowsSelected(),
          //     indeterminate: table.getIsSomeRowsSelected(),
          //     onChange: table.getToggleAllRowsSelectedHandler(),
          //   }}
          // />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
                disabled: row.getValue('status') !== 'published',
              }}
            />
          </div>
        ),
      },
      {
        accessorKey: 'title',
        header: () => '헤드라인',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'editorName',
        header: () => '작성자',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'status',
        header: () => '상태',
        cell: (info) => <StatusBadge state={info.getValue() as string} />,
      },
      {
        accessorKey: 'today',
        header: () => '오늘의기사',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'createdAt',
        header: () => '등록일시',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'actions',
        header: () => '',
        cell: (info) => (
          <HStack spacing="0" justifyContent="flex-end">
            <IconButton
              icon={<FiEye fontSize="1.2rem" />}
              variant="ghost"
              aria-label="View"
              onClick={() => setShow(info.row.original!.id)}
            />
            <IconButton
              icon={<FiBell fontSize="1.2rem" />}
              variant="ghost"
              aria-label="Notify"
              isDisabled={info.row.original!.status === 'draft'}
              onClick={() => setNoti(info.row.original!.id)}
            />
            <IconButton
              icon={<FiEdit fontSize="1rem" />}
              variant="ghost"
              aria-label="Edit"
              isDisabled={
                !isAdmin &&
                !['draft', 'completed'].includes(info.row.original!.status)
              }
              onClick={() => setEdit(info.row.original!.id)}
            />
            <IconButton
              icon={<FiTrash2 fontSize="1.2rem" />}
              variant="ghost"
              color="red.300"
              aria-label="Delete"
              isDisabled={!isAdmin}
              onClick={() => setRemove(info.row.original!)}
            />
          </HStack>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Stack spacing={{ base: '8', lg: '6' }}>
      <Stack
        spacing="4"
        direction={{ base: 'column', lg: 'row' }}
        justify="space-between"
        align={{ base: 'start', lg: 'end' }}
      >
        <Stack spacing="1">
          <Heading size={{ base: 'xs', lg: 'sm' }} fontWeight="medium">
            기사관리
          </Heading>
          <HStack spacing={1}>
            <DatePicker
              selectedDate={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
            <DatePicker
              selectedDate={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
            <IconButton
              size="sm"
              icon={<DownloadIcon />}
              aria-label="데이터 불러오기"
              isDisabled={loading}
              onClick={changeRange}
            />
            <IconButton
              size="sm"
              icon={<RepeatIcon />}
              aria-label="초기화"
              onClick={initDate}
            />
          </HStack>
        </Stack>
        <HStack spacing="3">
          <Button variant="primary" onClick={async () => createDraft()}>
            기사 작성
          </Button>
        </HStack>
      </Stack>

      {loading || !data ? (
        <Spinner />
      ) : (
        <GenericTable<Article>
          name="기사목록"
          data={data}
          columns={columns}
          initialSorting={[{ id: 'createdAt', desc: true }]}
        />
      )}
    </Stack>
  );
};
