import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {
  addDoc,
  query,
  collection,
  where,
  getDocs,
  doc,
  serverTimestamp,
  orderBy,
  limit,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import {
  Button,
  CloseButton,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import { rowsAtom, selectionAtom } from '.';
import { db } from 'lib/firebase';
import { RadioCardGroup, RadioCard } from 'components/RadioCardGroup';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';
import { SummaryConverter } from 'features/summaries/domain/Summary';

export const MultipleAction = () => {
  const [processing, setProcessing] = useState(false);
  const [summaryId, setSummaryId] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const [, setSelection] = useAtom(selectionAtom);
  const [rows] = useAtom(rowsAtom);

  const toastSuccess = useToastSuccess();
  const toastError = useToastError();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const selectBg = useColorModeValue('white', 'gray.800');
  const modalFooterBg = useColorModeValue('gray.50', 'gray.600');
  // const textColor = useColorModeValue('gray.800', 'gray.600');

  useEffect(() => {
    const now = dayjs();
    const tomorrow = dayjs().add(1, 'day');

    const currentDate = now.hour() >= 19 && now.hour() < 24 ? tomorrow : now;
    const timeNow = now.hour() >= 7 && now.hour() < 19 ? '19' : '07';

    setDate(currentDate.format('YYYY-MM-DD'));
    setTime(timeNow);
  }, []);

  const [value, loading] = useCollectionData(
    isOpen
      ? query(
          collection(db, 'summaries'),
          orderBy('dateTime', 'desc'),
          limit(5)
        ).withConverter(SummaryConverter)
      : null
  );

  const changeDate = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.currentTarget.value);
  }, []);

  const changeTime = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    setTime(event.currentTarget.value);
  }, []);

  const createSummary = useCallback(
    async () => {
      if (!date || !time) return;
      setProcessing(true);
      try {
        const dateTime = new Date(`${date}T${time}:00:00`);
        const q = query(
          collection(db, 'summaries'),
          where('dateTime', '==', dateTime)
        );
        const snapshots = await getDocs(q);

        if (snapshots.size > 0) {
          throw Error('걑은 일시의 AM7/PM7이 이미 있습니다');
        }

        await addDoc(collection(db, 'summaries'), {
          dateTime,
          status: 'pending',
          createdAt: serverTimestamp(),
        });

        toastSuccess({ description: '새로운 AM7/PM7을 만들었습니다' });
      } catch (error) {
        if (error instanceof Error) {
          toastError({ description: error.message });
        }
      } finally {
        setProcessing(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, time]
  );

  const assignArticles = useCallback(async () => {
    if (!summaryId || !rows) return;
    setProcessing(true);
    try {
      const summaryRef = doc(db, 'summaries', summaryId);
      await updateDoc(summaryRef, {
        articles: arrayUnion(...rows),
      });

      toastSuccess({ description: '기사를 담았습니다' });
      setSelection({});
      onClose();
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    } finally {
      setProcessing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryId]);

  if (!rows) {
    return <></>;
  }

  return (
    <>
      <HStack
        bg="blue.100"
        color="gray.800"
        px={{ base: 4, md: 6 }}
        py="2"
        align="center"
        justifyContent="space-between"
        fontSize="sm"
        spacing="3"
      >
        <HStack spacing="3">
          <Text>AM7/PM7 에 담을까요?</Text>
          <Button
            type="button"
            variant="outline"
            fontSize="sm"
            onClick={onOpen}
          >
            선택
          </Button>
        </HStack>
        <CloseButton onClick={() => setSelection({})} />
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent overflow="hidden">
          <ModalHeader>새로운 AM7/PM7 만들기</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={5}>
            <Stack spacing={5}>
              <HStack spacing={2}>
                <Input
                  flex={3}
                  type="date"
                  fontSize="sm"
                  value={date}
                  onChange={changeDate}
                />
                <Select
                  flex={2}
                  fontSize="sm"
                  bg={selectBg}
                  value={time}
                  onChange={changeTime}
                >
                  <option value="07">AM7</option>
                  <option value="19">PM7</option>
                </Select>
                <Button
                  type="button"
                  flex={3}
                  fontSize="sm"
                  disabled={!date || !time}
                  isLoading={processing}
                  onClick={createSummary}
                >
                  새 AM7/PM7 생성
                </Button>
              </HStack>
              {!loading &&
                (value?.length ? (
                  <RadioCardGroup
                    spacing="2"
                    defaultValue={summaryId}
                    onChange={setSummaryId}
                  >
                    {value.map((summary) => (
                      <RadioCard key={summary.id} value={summary.id}>
                        <Text
                          color="emphasized"
                          fontWeight="semibold"
                          fontSize="sm"
                        >
                          {summary.dateTime}
                        </Text>
                        <Text color="muted" fontSize="sm">
                          <strong>{summary.articles?.length || 0}</strong>개의
                          기사가 담겨져 있습니다.
                        </Text>
                      </RadioCard>
                    ))}
                  </RadioCardGroup>
                ) : (
                  <Stack
                    w="full"
                    h="full"
                    rounded="lg"
                    border="3px dashed currentColor"
                    color="gray.300"
                    alignItems="center"
                    py="12"
                    // color={useColorModeValue('gray.200', 'gray.700')}
                  >
                    <Text>AM7/PM7이 없습니다.</Text>
                  </Stack>
                ))}
            </Stack>
          </ModalBody>
          <ModalFooter bg={modalFooterBg}>
            <Button
              type="button"
              variant="primary"
              isLoading={processing}
              disabled={!summaryId}
              onClick={assignArticles}
            >
              기사 담기
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
