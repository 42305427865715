import Typesense from 'typesense';
import { ConfigurationOptions } from 'typesense/lib/Typesense/Configuration';

const typesenseConfig = {
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST!,
      port: parseInt(process.env.REACT_APP_TYPESENSE_PORT!),
      protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL!,
    },
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_API_KEY!,
  numRetries: 3, // A total of 4 tries (1 original try + 3 retries)
  connectionTimeoutSeconds: 10,
  retryIntervalSeconds: 0.1,
  healthcheckIntervalSeconds: 2,
  logLevel: 'error',
} as ConfigurationOptions;

export const typesense = new Typesense.Client(typesenseConfig);
