const resizeImage = (
  file: File,
  type: string,
  maxWidth = 1280,
  maxHeight = 1280
): Promise<string | Blob | null> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      const width = image.width;
      const height = image.height;

      let newWidth;
      let newHeight;

      if (width <= maxWidth && height <= maxHeight) {
        newWidth = width;
        newHeight = height;
      } else {
        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }
      }

      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;

      const context = canvas.getContext('2d');
      context!.drawImage(image, 0, 0, newWidth, newHeight);

      if (type === 'string') {
        resolve(canvas.toDataURL('image/jpeg'));
      } else {
        canvas.toBlob(resolve, file.type);
      }
    };
    image.onerror = reject;
  });
};

export default resizeImage;
