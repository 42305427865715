import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { Control, useWatch } from 'react-hook-form';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { imagesAtom } from 'features/upload';
import { ArticleFormValues } from '../../domain/Article';
import { PreviewBody } from './PreviewBody';
import { JSONtoHTML } from 'lib/parser';
import { Related } from 'features/articles/domain/Related';

export const PreviewForEdit = ({
  control,
  relatedExt,
  relatedInt,
}: {
  control: Control<ArticleFormValues>;
  relatedExt: Related[];
  relatedInt: Related[];
}) => {
  const [images] = useAtom(imagesAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const title = useWatch({ control, name: 'title' });
  const body = useWatch({ control, name: 'body' });
  const excerpt = useWatch({ control, name: 'excerpt' });
  const caption = useWatch({ control, name: 'imageCaption' });
  const source = useWatch({ control, name: 'imageSource' });

  const bodyHTML = useMemo(() => JSONtoHTML(body), [body]);
  const excerptHTML = useMemo(() => JSONtoHTML(excerpt), [excerpt]);
  const preview = useMemo(
    () => ({
      title,
      body: bodyHTML,
      excerpt: excerptHTML,
      image: images.length > 0 ? images[0].url : null,
      caption,
      source,
      relatedExt,
      relatedInt,
    }),
    [
      title,
      bodyHTML,
      excerptHTML,
      caption,
      source,
      images,
      relatedExt,
      relatedInt,
    ]
  );

  return (
    <>
      <Button type="button" variant="outline" onClick={onOpen}>
        미리보기
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>미리보기</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PreviewBody preview={preview} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
