import { ChangeEvent, useCallback, useState } from 'react';
import { useAtom } from 'jotai';
import {
  collection,
  deleteField,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { Button, HStack, Input, Stack, Text } from '@chakra-ui/react';

import { db } from 'lib/firebase';
import { Word } from '../domain/Word';
import { appUserAtom } from 'index';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';

export const TodayWord = ({ word }: { word?: Word }) => {
  const [processing, setProcessing] = useState(false);
  const [date, setDate] = useState('');

  const [appUser] = useAtom(appUserAtom);
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();

  const changeDate = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.currentTarget.value);
  }, []);

  const setTodayWord = useCallback(
    async () => {
      if (!word || !date) return;
      setProcessing(true);
      try {
        const today = new Date(`${date}T00:00:00`);
        const q = query(collection(db, 'words'), where('today', '==', today));
        const snapshots = await getDocs(q);

        if (snapshots.size > 0) {
          throw Error('걑은 날로 선정된 한마디가 이미 있습니다');
        }

        await updateDoc(doc(db, 'words', word.id), { today });
        toastSuccess({ description: '오늘의한마디로 선정했습니다' });
      } catch (error) {
        if (error instanceof Error) {
          toastError({ description: error.message });
        }
      } finally {
        setProcessing(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date]
  );

  const cancelTodayWord = useCallback(
    async () => {
      if (!word) return;
      setProcessing(true);
      try {
        await updateDoc(doc(db, 'words', word.id), {
          today: deleteField(),
        });
        toastSuccess({ description: '오늘의한마디 취소했습니다' });
      } catch (error) {
        if (error instanceof Error) {
          toastError({ description: error.message });
        }
      } finally {
        setProcessing(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [word]
  );

  if (appUser?.role !== 'chief' || !word) {
    return <></>;
  }

  return (
    <Stack>
      {word.today ? (
        <HStack spacing={3}>
          <HStack flex={1}>
            <Text>설정일</Text>
            <Text fontWeight="semibold">{word.today}</Text>
          </HStack>
          <Button flex={1} isLoading={processing} onClick={cancelTodayWord}>
            오늘의 한마디 취소
          </Button>
        </HStack>
      ) : (
        <HStack>
          <Input flex={1} type="date" fontSize="sm" onChange={changeDate} />
          <Button
            flex={1}
            fontSize="sm"
            disabled={!date}
            isLoading={processing}
            onClick={setTodayWord}
          >
            오늘의 한마디로 선정
          </Button>
        </HStack>
      )}
    </Stack>
  );
};
