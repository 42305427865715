import { PropsWithChildren, useEffect, useState } from 'react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  useReactTable,
  flexRender,
  ColumnDef,
} from '@tanstack/react-table';
import {
  Box,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Icon,
  useBreakpointValue,
  Button,
  ButtonGroup,
  HStack,
  Select,
} from '@chakra-ui/react';
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';

import { DebouncedInput } from './DebouncedInput';
import { useAtom } from 'jotai';
import { rowsAtom, selectionAtom } from 'features/articles/presentation';
import { MultipleAction } from 'features/articles/presentation/MutipleAction';

export interface TableProperties<T extends Record<string, unknown>> {
  name: string;
  columns: ColumnDef<T>[];
  data: Array<T>;
  initialSorting?: SortingState;
  pageSize?: number;
  unsearchable?: boolean;
}

export const GenericTable = <T extends Record<string, unknown>>(
  props: PropsWithChildren<TableProperties<T>>
) => {
  const { name, columns, data, initialSorting, pageSize, unsearchable } = props;

  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<SortingState>(initialSorting || []);
  const [rowSelection, setRowSelection] = useAtom(selectionAtom);
  const [, setRows] = useAtom(rowsAtom);

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    return () => {
      setRowSelection({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!Object.keys(rowSelection).length) {
      setRows(null);
      return;
    }
    const ids: string[] = Object.keys(rowSelection).map(
      (idx) => data[parseInt(idx)].id as string
    );
    setRows(ids);
  }, [rowSelection, setRows, data]);

  const instance = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: { pageSize: pageSize || 10 },
    },
  });

  return (
    <Box
      bg="bg-surface"
      boxShadow={{ base: 'none', md: 'sm' }}
      borderRadius={{ base: 'none', md: 'lg' }}
    >
      <Stack spacing="5">
        <Box px={{ base: '4', md: '6' }} pt="5">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="center"
            justify="space-between"
          >
            <Text fontSize="lg" fontWeight="semibold">
              {name}
            </Text>
            {unsearchable !== true && (
              <DebouncedInput
                value={globalFilter ?? ''}
                onChange={(value) => setGlobalFilter(String(value))}
              />
            )}
          </Stack>
        </Box>
        <Box overflowX="auto">
          <MultipleAction />
          <Table>
            <Thead>
              {instance.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <Stack
                          direction="row"
                          alignItems="center"
                          {...{
                            sx: header.column.getCanSort()
                              ? { cursor: 'pointer', select: 'none' }
                              : {},
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          <Text>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Text>
                          {{
                            asc: (
                              <Icon
                                as={IoArrowUp}
                                color="muted"
                                boxSize="4"
                                aria-label="sorted ascending"
                              />
                            ),
                            desc: (
                              <Icon
                                as={IoArrowDown}
                                color="muted"
                                boxSize="4"
                                aria-label="sorted descending"
                              />
                            ),
                          }[header.column.getIsSorted() as string] ?? null}
                        </Stack>
                      )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {instance.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <Box px={{ base: '4', md: '6' }} pb="5">
          <Stack
            direction="row"
            spacing="3"
            alignItems="center"
            justify={{ base: 'center', md: 'space-between' }}
          >
            {!isMobile && (
              <Text color="muted" fontSize="sm">
                Page {instance.getState().pagination.pageIndex + 1} of{' '}
                {instance.getPageCount()}
              </Text>
            )}
            <HStack spacing={3}>
              <ButtonGroup spacing="1" variant="secondary">
                <Button
                  onClick={() => instance.setPageIndex(0)}
                  disabled={!instance.getCanPreviousPage()}
                >
                  {'<<'}
                </Button>
                <Button
                  onClick={() => instance.previousPage()}
                  disabled={!instance.getCanPreviousPage()}
                >
                  {'<'}
                </Button>
                <Button
                  onClick={() => instance.nextPage()}
                  disabled={!instance.getCanNextPage()}
                >
                  {'>'}
                </Button>
                <Button
                  onClick={() =>
                    instance.setPageIndex(instance.getPageCount() - 1)
                  }
                  disabled={!instance.getCanNextPage()}
                >
                  {'>>'}
                </Button>
              </ButtonGroup>
              <Select
                fontSize="sm"
                value={instance.getState().pagination.pageSize}
                onChange={(e) => {
                  instance.setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    페이지당 {pageSize}건
                  </option>
                ))}
              </Select>
            </HStack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
