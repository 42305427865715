import { atom, Provider } from 'jotai';

import { Message } from '../domain/Message';
import { MessagesList } from './MessagesList';
import { MessageEdit } from './MessageEdit';
import { MessageDelete } from './MessageDelete';

const messageScope = Symbol();
export const editAtom = atom<string | null>(null);
export const removeAtom = atom<Message | null>(null);

export const Messages = () => {
  return (
    <Provider scope={messageScope}>
      <MessagesList />
      <MessageEdit />
      <MessageDelete />
    </Provider>
  );
};
