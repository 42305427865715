import { useMemo, useCallback, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { db } from 'lib/firebase';
import { editAtom } from '.';
import { useToastSuccess, useToastError } from 'components/start-ui/Toast';
import { SummaryConverter } from '../domain/Summary';
import { DragContainer, Item } from 'components/dnd/DragContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const collectionName = 'summaries';

export const SummaryEdit = () => {
  const [processing, setProcessing] = useState(false);
  const [cards, setCards] = useState<Item[]>([]);
  const [edit, setEdit] = useAtom(editAtom);

  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const footerBg = useColorModeValue('gray.50', 'gray.600');

  const isOpen = useMemo(() => !!edit, [edit]);
  const onClose = useCallback(() => {
    setEdit(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [value, loading] = useDocumentData(
    edit && edit !== 'new'
      ? doc(db, collectionName, edit).withConverter(SummaryConverter)
      : null
  );

  useEffect(() => {
    const fetchArticles = async () => {
      if (!value) return;
      const items: { [index: string]: string } = {};
      const ids = value.articles.map((id) => id);
      if (ids) {
        const q = query(
          collection(db, 'articles'),
          where('__name__', 'in', ids)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          items[doc.id] = doc.data().title;
        });
        setCards(value.articles.map((id) => ({ id, text: items[id] })));
      }
    };
    fetchArticles();
  }, [value]);

  const onSubmit = async () => {
    setProcessing(true);
    try {
      await updateDoc(doc(db, collectionName, edit!), {
        articles: cards.map((card) => card.id),
      });
      toastSuccess({ description: 'AM7/PM7을 수정했습니다' });
      onClose();
    } catch (error) {
      if (error instanceof Error) {
        toastError({ description: error.message });
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>AM7/PM7 {edit === 'new' ? '작성' : '수정'}</DrawerHeader>
        <DrawerBody py="5">
          {!loading && value && (
            <Stack spacing={4}>
              <Text fontWeight="bold">{value.dateTime}</Text>
              <Text color="muted" fontSize="xs">
                기사제목 박스를 드래그해서 순서를 변경할 수 있습니다. 순서변경
                또는 삭제 후 저장 버튼을 눌러야 변경사항이 반영됩니다.
              </Text>
              {cards.length && (
                <DndProvider backend={HTML5Backend}>
                  <DragContainer cards={cards} setCards={setCards} />
                </DndProvider>
              )}
            </Stack>
          )}
        </DrawerBody>
        <DrawerFooter bg={footerBg}>
          {value && value.status !== 'completed' && (
            <Button
              type="button"
              variant="primary"
              isLoading={processing}
              onClick={onSubmit}
            >
              저장
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
