import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from 'lib/firebase';
import {
  As,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from 'ColorModeSwitcher';
import {
  FiBell,
  FiBookOpen,
  FiClock,
  FiEdit3,
  FiLogOut,
  FiMessageCircle,
} from 'react-icons/fi';
import { BiMoney, BiNews } from 'react-icons/bi';
import { MdOutlineQuiz } from 'react-icons/md';

import { Logo } from './Logo';
import { NavButton } from './NavButton';
import { UserProfile } from './UserProfile';
import { appUserAtom } from 'index';

export type Navigation = {
  id: string;
  href: string;
  label: string;
  icon: As;
};

const nav: Navigation[] = [
  { id: 'articles', href: '/articles', label: '기사', icon: BiNews },
  { id: 'words', href: '/words', label: '한마디', icon: FiMessageCircle },
];

const navAdmin: Navigation[] = [
  { id: 'summaries', href: '/summaries', label: 'AM7/PM7', icon: FiClock },
  { id: 'messages', href: '/messages', label: '푸시알림', icon: FiBell },
  { id: 'quizzes', href: '/quizzes', label: '퀴즈', icon: MdOutlineQuiz },
  {
    id: 'encashments',
    href: '/encashments',
    label: '현금전환신청',
    icon: BiMoney,
  },
  { id: 'editors', href: '/editors', label: '필자', icon: FiEdit3 },
  { id: 'docs', href: '/docs', label: '문서', icon: FiBookOpen },
];

export const Sidebar = () => {
  const location = useLocation();
  const [appUser] = useAtom(appUserAtom);

  const navigations = useMemo(() => {
    return appUser?.role === 'chief' ? [...nav, ...navAdmin] : nav;
  }, [appUser]);

  return (
    <Flex as="section" minH="100vh" bg="bg-canvas">
      <Flex
        flex="1"
        bg="bg-surface"
        overflowY="auto"
        boxShadow={useColorModeValue('sm', 'sm-dark')}
        maxW={{ base: 'full', sm: 'xs' }}
        py={{ base: '6', sm: '8' }}
        px={{ base: '4', sm: '6' }}
      >
        <Stack justify="space-between" spacing="1">
          <Stack spacing={{ base: '5', sm: '6' }} shouldWrapChildren>
            <HStack justifyContent="center">
              <Logo />
              <Text color="muted" fontWeight="bold">
                CMS
              </Text>
            </HStack>
            {/* <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={FiSearch} color="muted" boxSize="5" />
              </InputLeftElement>
              <Input placeholder="Search" isDisabled={true} />
            </InputGroup> */}
            <Flex justifyContent="center">
              <ColorModeSwitcher w={220} ml={0} />
            </Flex>
            <Stack spacing="1">
              {/* <NavButton
                label="Home"
                icon={FiHome}
                href="/"
                aria-current={location.pathname === '/' ? 'page' : undefined}
              />
              <NavButton label="Dashboard" icon={FiBarChart2} /> */}
              {navigations.map((item) => (
                <NavButton
                  key={item.id}
                  label={item.label}
                  icon={item.icon}
                  href={item.href}
                  aria-current={
                    location.pathname === item.href ? 'page' : undefined
                  }
                />
              ))}
              {/* <NavButton label="필진" icon={FiUsers} />
              <NavButton
                label="테스트페이지"
                icon={FiRefreshCcw}
                href="/foobar"
                aria-current={
                  location.pathname.startsWith('/foobar') ? 'page' : undefined
                }
              /> */}
            </Stack>
          </Stack>
          <Stack spacing={{ base: '5', sm: '6' }}>
            <Stack spacing="1">
              {/* <NavButton label="Help" icon={FiHelpCircle} />
              <NavButton label="Settings" icon={FiSettings} /> */}
              <NavButton
                label="로그아웃"
                icon={FiLogOut}
                onClick={() => signOut(auth)}
              />
            </Stack>
            {/* <Box bg="bg-subtle" px="4" py="5" borderRadius="lg">
              <Stack spacing="4">
                <Stack spacing="1">
                  <Text fontSize="sm" fontWeight="medium">
                    Almost there
                  </Text>
                  <Text fontSize="sm" color="muted">
                    Fill in some more information about you and your person.
                  </Text>
                </Stack>
                <Progress
                  value={80}
                  size="sm"
                  aria-label="Profile Update Progress"
                />
                <HStack spacing="3">
                  <Button variant="link" size="sm">
                    Dismiss
                  </Button>
                  <Button variant="link" size="sm" colorScheme="blue">
                    Update profile
                  </Button>
                </HStack>
              </Stack>
            </Box> */}
            <Divider />
            <UserProfile />
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  );
};
