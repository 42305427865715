import { JSONContent } from '@tiptap/react';
import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';
import { dateFormat } from 'lib/utils';

export type Message = {
  id: string;
  title: string;
  body?: string;
  content?: JSONContent;
  contentHTML?: string;
  category: string;
  status: string;
  articleId?: string;
  dueDate?: string;
  dueTime?: string;
  dueMinute?: string;
  scheduledAt?: string;
  sentAt?: string;
  createdAt: string;
  updatedAt?: string;
};

export type MessageFormValues = {
  title: string;
  body?: string;
  content?: JSONContent;
  category: string;
  articleId?: string;
  dueDate?: string;
  dueTime?: string;
  dueMinute?: string;
};

export const MessageConverter: FirestoreDataConverter<Message> = {
  toFirestore(message: WithFieldValue<Message>): DocumentData {
    return {
      title: message.title,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Message {
    const data = snapshot.data(options);

    let dueDate = '',
      dueTime = '',
      dueMinute = '';
    if (data.scheduledAt) {
      const scheduledAt = data.scheduledAt.toDate();
      dueDate = dateFormat(scheduledAt, 'YYYY-MM-DD') || '';
      dueTime = dateFormat(scheduledAt, 'HH') || '';
      dueMinute = dateFormat(scheduledAt, 'mm') || '';
    }

    return {
      id: snapshot.id,
      title: data.title,
      body: data.body,
      content: data.content || { content: [], type: 'doc' },
      contentHTML: data.contentHTML,
      category: data.category,
      status: data.status,
      articleId: data.articleId,
      ...(dueDate && { dueDate }),
      ...(dueTime && { dueTime }),
      ...(dueMinute && { dueMinute }),
      scheduledAt: dateFormat(data.scheduledAt?.toDate()) || '-',
      sentAt: dateFormat(data.sentAt?.toDate()) || '-',
      createdAt: dateFormat(data.createdAt?.toDate()) || '-',
      updatedAt: dateFormat(data.updatedAt?.toDate()) || '-',
    };
  },
};
