import { atom, Provider } from 'jotai';
import { RowSelectionState } from '@tanstack/react-table';

import { Article } from '../domain/Article';
import { ArticleShow } from './ArticleShow';
import { ArticleEdit } from './ArticleEdit';
import { ArticleDelete } from './ArticleDelete';
import { ArticlesList } from './ArticlesList';
import { MessageNew } from 'features/messages/presentation/MessageNew';

const articleScope = Symbol();
export const showAtom = atom<string | null>(null);
export const editAtom = atom<string | null>(null);
export const removeAtom = atom<Article | null>(null);
export const selectionAtom = atom<RowSelectionState>({});
export const rowsAtom = atom<string[] | null>(null);
export const notiAtom = atom<string | null>(null);

export const Articles = () => {
  return (
    <Provider scope={articleScope}>
      <ArticlesList />
      <ArticleShow />
      <ArticleEdit />
      <ArticleDelete />
      <MessageNew />
    </Provider>
  );
};
