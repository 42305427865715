import { useCallback } from 'react';
// import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Button, Container } from '@chakra-ui/react';
import axios from 'axios';

// import { db, storage } from 'lib/firebase';

export const MigrationIndex = () => {
  const onClick = useCallback(async () => {
    const response = await axios.get<Blob>(
      'https://d3vnt6byjajzpu.cloudfront.net/article/1663136868183.png',
      { responseType: 'blob' }
    );

    console.log(response);
    // const fileName = dayjs().format('YYYYMMDDHHmmss.SSS');
    // const storageRef = ref(storage, `articles/${articleId}/${fileName}.jpg`);
    // const snapshot = await uploadBytes(storageRef, imageBlob);
    // const downloadURL = await getDownloadURL(snapshot.ref);
  }, []);

  return (
    <Container>
      <Button onClick={onClick}>CLICK</Button>
    </Container>
  );
};
