import { useCallback, useMemo } from 'react';
import { useAtom } from 'jotai';
import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
} from '@chakra-ui/react';

import { showAtom } from '.';
import { PreviewBody } from './preview/PreviewBody';
import { ControlPanel } from './ControlPanel';
import { db } from 'lib/firebase';
import { ArticleDetailConverter } from '../domain/Article';

export const ArticleShow = () => {
  const [show, setShow] = useAtom(showAtom);
  const isOpen = useMemo(() => !!show, [show]);
  const onClose = useCallback(() => setShow(null), [setShow]);

  const [article] = useDocumentData(
    show
      ? doc(db, 'articles', show).withConverter(ArticleDetailConverter)
      : null
  );

  const preview = useMemo(() => {
    if (!article) return undefined;
    return {
      title: article.title,
      body: article.body,
      excerpt: article.excerpt,
      image: article.imageURL,
      caption: article.imageCaption,
      source: article.imageSource,
      relatedExt: article.relatedExt,
      relatedInt: article.relatedInt,
      publishedAt: article.publishedAt,
    };
  }, [article]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack>
            <Text>기사관리</Text>
            <ControlPanel article={article} />
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PreviewBody preview={preview} />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
